import React from "react";
import * as LiteElements from "karma_lib/component/LiteElements.js";

// Handles whitespaces and line breaks ('\n'). 
export const createText1 = function(inputStr) {
    return createText2(inputStr, "0px");
}

// https://stackoverflow.com/questions/47313946/how-can-i-dynamically-generate-dom-elements-using-reactjs
export const createText2 = function(inputStr, verticalSpacing) {
    let parseArray = inputStr.split("\n");
    let resultArray = [];
    for (let index = 0; index < parseArray.length; index++) {
        {    
            let objInfo = {};
            let newStr = replaceAll(parseArray[index], ' ', "");
            if (newStr.length > 0) {
                let preStr = parseArray[index];
                objInfo["value"] = preStr; //replaceAll(preStr, ' ', "&nbsp");
            } else {
                objInfo["value"] = ""; // If string was entirely white space, replace with empty string.
            }   
            resultArray.push(objInfo);
        }

        {
            let nextIndex = index + 1;
            if (nextIndex < parseArray.length) {
                let objInfo = {};
                objInfo["is_padding"] = true;
                resultArray.push(objInfo);
            }
        }
    }
    
    let retArray;
    let USE_ARRAY_LOOP = true; // Either way is the same result, just different style.
    if (USE_ARRAY_LOOP) {
        retArray = [];
        for (let index = 0; index < resultArray.length; index++) {
            let objInfo = resultArray[index];
            let value = objInfo["value"];

            let curElem;
            if (objInfo["is_padding"]) {
                let elem1 = <div style={{"height": verticalSpacing}}></div>
                curElem = <React.Fragment key={index}>{elem1}</React.Fragment>;
            } else {
                if (value === "") {
                    let elem2 = <br/>;
                    curElem = <React.Fragment key={index}>{elem2}</React.Fragment>;
                } else {
                    let newValue = formatStringWithSpaces(value);
                    curElem = <React.Fragment key={index}>{newValue}</React.Fragment>;
                    //curlem = React.createElement("div", { key: index }, value);
                }
            }
            retArray.push(curElem);
        }
    } else {
        retArray = resultArray.map((objInfo, index) => {
                    let value = objInfo["value"];
                    let curlem;
                    if (objInfo["is_padding"]) {
                        let elem1 = <div style={{"height": verticalSpacing}}></div>
                        curlem = <React.Fragment key={index}>{elem1}</React.Fragment>;
                    } else {
                        if (value === "") {
                            let elem2 = <br/>;
                            curlem = <React.Fragment key={index}>{elem2}</React.Fragment>;
                        } else {
                            let newValue = formatStringWithSpaces(value);
                            curlem = <React.Fragment key={index}>{newValue}</React.Fragment>;
                            //curlem = React.createElement("div", { key: index }, value);
                        }
                    }
                    return curlem;
                })
    }

    return <>{retArray}</>
}

// Group strings by chunks of non-spaces and spaces.
function groupStrings(value) {
    let indicesToExamine = [];
    {
        let refCharStr;
        for (let index = 0; index < value.length; index++) {
            let curCharStr = value[index];
            if (index == 0) {
                refCharStr = curCharStr;
                indicesToExamine.push(index);
            } else {
                if (refCharStr != " " && curCharStr != " ") {
                } else if (refCharStr == " " && curCharStr == " ") {
                } else {
                    refCharStr = curCharStr;
                    indicesToExamine.push(index);
                }
            }
        }
    }
    indicesToExamine.push(value.length);

    let newArray = [];
    for (let index = 0; index < indicesToExamine.length - 1; index++) {
        let localStartIdx = indicesToExamine[index];
        let localEndIdx = indicesToExamine[index + 1];
        let tempStr = value.substring(localStartIdx, localEndIdx);
        newArray.push(tempStr);
    }
    return newArray;
}

const EMPTY_SPACE_WIDTH = 4;
// Eric comment: Using nbsp; didn't work, so this was written.
function formatStringWithSpaces(value) {
    let newArray = groupStrings(value);

    let totalElem = [];
    for (let index = 0; index < newArray.length; index++) {
        let curStr = newArray[index];
        if (curStr.indexOf(" ") == -1) {
            totalElem.push(<span key={index}>{curStr}</span>)
        } else {
            let marginVal;
            if (curStr.length == 1) {
                marginVal = 0;
            } else {
                marginVal = curStr.length * EMPTY_SPACE_WIDTH;
            }
            let marginStr = marginVal + "px";
            let styleObj = {
                "marginLeft": marginStr
            }
            totalElem.push(<span key={index} style={styleObj}>{curStr}</span>);
            // https://stackoverflow.com/questions/224602/how-do-you-make-div-elements-display-inline
        }
    }        
    return totalElem;
}

function replaceAll(str, find, replace) {
    return str.replace(new RegExp(escapeRegExp(find), 'g'), replace);
}

function escapeRegExp(string) {
    return string.replace(/[.*+\-?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
}
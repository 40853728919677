import * as RequestUtil from "http_request/RequestUtil.js";
import * as ArrayUtil from  "karma_lib/util/ArrayUtil.js";
import * as StorageUtil from "karma_lib/util/StorageUtil.js";
import * as RequestParseUtil from "karma_lib/util/RequestParseUtil.js";

const ROUTE_SUFFIX = "/ua_partner/daily_report_fetch";

export const getDailyReportInputs = function(callback) {
    let params = {};
    params["is_inputs_only"] = true;
    params["utm_source"] = StorageUtil.getSelectedUtmSource(); // Only for admin, ignored  otherwise.
    let fullUrl = RequestUtil.createFullUrlSimple(ROUTE_SUFFIX, params);
    RequestUtil.requestGET(fullUrl,
        function(errorInfo, respData) {
            if (errorInfo) {
                callback(errorInfo, null);
            } else {
                let responseInfo = {};
                responseInfo["application_choice_array"] = RequestParseUtil.parseInputArray(respData["application_choice_array"])
                callback(null, responseInfo);
            }
    });
}

export const fetchDailyReport = function(applicationArray, platformArray, startDate, endDate, callback) {
    let params = {};
    params["application_json_array_str" ] = JSON.stringify(applicationArray);
    params["platform_json_array_str"] = JSON.stringify(platformArray);
    params["start_date_str"] = startDate.toISOString();
    params["end_date_str"] = endDate.toISOString();
    params["utm_source"] = StorageUtil.getSelectedUtmSource(); // Only for admin, ignored  otherwise.
    var fullUrl = RequestUtil.createFullUrlSimple(ROUTE_SUFFIX, params);
    RequestUtil.requestGET(fullUrl,
        function(errorInfo, respData) {
            if (errorInfo) {
                callback(errorInfo);
            } else {
                respData["reportStartDate"] = new Date(respData["report_start_date_str"]);
                respData["reportEndtDate"] = new Date(respData["report_end_date_str"]);

                let responseInfo = {};
                responseInfo["resp_data"] = respData;
                callback(null, responseInfo);
            }
    });
}
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Dropdown, Modal, ModalBody } from 'react-bootstrap';
import settings from 'assets/img/team/settings.png';
import Avatar from 'components/common/Avatar';
import * as LogoutRequest from 'http_request/LogoutRequest.js';
import * as StorageUtil from 'karma_lib/util/StorageUtil.js';
import ModalHeader from 'react-bootstrap/esm/ModalHeader';

const ProfileDropdown = () => {
  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  return (
    <div>
      <Dropdown navbar={true} as="li">
        <Dropdown.Toggle
          bsPrefix="toggle"
          as={Link}
          to="#!"
          className="pe-0 ps-2 nav-link"
        >
          <Avatar src={settings} />
        </Dropdown.Toggle>

        <Dropdown.Menu className="dropdown-menu-card  dropdown-menu-end">
          <div className="bg-white rounded-2 py-2 dark__bg-1000">
            <Dropdown.Item className="fw-bold text-warning" href="#!">
              <FontAwesomeIcon icon="crown" className="me-1" />
              <span>{StorageUtil.getUserEmail()}</span>
            </Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Item className="fw-bold" href="/contact-us">
              <span>Contact Us</span>
            </Dropdown.Item>
            <Dropdown.Divider />
            {/* <Dropdown.Item href="#!">Set status</Dropdown.Item>
          <Dropdown.Item as={Link} to="/user/profile">
            Profile &amp; account
          </Dropdown.Item>
          <Dropdown.Item href="#!">Feedback</Dropdown.Item>
          <Dropdown.Divider /> */}
            <Dropdown.Item as={Link} to="/password_change">
              Password Change
            </Dropdown.Item>
            <Dropdown.Item
              // as={Link}
              // to="/login"
              onClick={() => {
                setShow(true);
              }}
            >
              Logout
            </Dropdown.Item>
          </div>
        </Dropdown.Menu>
      </Dropdown>
      <Modal show={show} centered>
        <ModalHeader><h5>Confirm log out</h5></ModalHeader>
        <ModalBody className="d-flex justify-content-end gap-2">
          <Button className="bg-danger" onClick={()=>setShow(false)}>Cancel</Button>
          <Button
            onClick={() => {
              LogoutRequest.notifyLogout(null);
              StorageUtil.handleSaveLoginCache();
              navigate('/login')
            }}
          >
            Yes
          </Button>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default ProfileDropdown;

import React, { useEffect, useState, useRef } from 'react';
import { Button, Form, OverlayTrigger, Popover, Table } from 'react-bootstrap';
import { MdDeleteOutline } from 'react-icons/md';
import CustomModal from './CustomModal';
import DropdownOption from './DropdownOption';
import InputDropdownOption from './InputDropDown';
import TooltipEx from './TooltipEx';
import DropdownOptionKeyPair from './DropdownOptionKeyPair';
import { BsArrowBarDown, BsArrowDown, BsArrowUp } from 'react-icons/bs';

const DeleteComponent = ({ setShow, cbClickConfirm }) => {
  return (
    <div>
      <div className="fw-semi-bold">You are about to delete this entry.</div>
      <div className="d-flex justify-content-end gap-2">
        <Button onClick={() => setShow(false)}>Cancel</Button>
        <Button
          onClick={() => {
            setShow(false);
            cbClickConfirm();
          }}
        >
          Submit
        </Button>
      </div>
    </div>
  );
};
const SearchData = ({
  data,
  setData,
  tableName,
  setPages,
  showDropDown,
  showSearchBar,
  pages,
  filterDropDownOption
}) => {
  // const [dropDownOption] = useState({
  //   campaign_stats_array:['Campaign',"Installs","Conver","Conversions","Flagged Total","Total Cost"]
  // });

  const [searchOption, setSearchOption] = useState(
    Object.keys(data).reduce((previous, tableName) => {
      let keys = Object.keys(data[tableName][0]);
      // filterDropDownOption[tableName] = keys;
      if (!filterDropDownOption[tableName]) {
        filterDropDownOption[tableName] = keys;
      }
      // console.log("Table key : ",keys, "filter : ",filterDropDownOption[tableName]);
      // keys.splice(0, 1);
      return {
        ...previous,
        [tableName]: {
          data: filterDropDownOption[tableName],
          selected: filterDropDownOption[tableName][0]
            ? filterDropDownOption[tableName][0]
            : ''
        }
      };
    }, [])
  );

  // console.log(
  //   'tableName : ',
  //   tableName,
  //   ' search Option : ',
  //   searchOption[tableName],
  //   searchOption
  // );
  return filterDropDownOption[tableName].length ? (
    <div className="d-flex justify-content-end">
      {showDropDown && (
        <InputDropdownOption
          className={''}
          style={{
            width: '40%'
          }}
          selected={searchOption[tableName].selected}
          setSelected={item =>
            setSearchOption({
              ...searchOption,
              [tableName]: { ...searchOption[tableName], selected: item }
            })
          }
          listItems={searchOption[tableName].data}
        />
      )}
      {showSearchBar && (
        <div
          className=" d-flex"
          style={{
            width: '60%'
          }}
        >
          <Form className="w-100 pe-2">
            <Form.Group>
              <Form.Control
                className={`${showDropDown ? 'rounded-start-0' : ''} w-100`}
                type="text"
                // placeholder={`Enter your ${searchOption[tableName].selected}`}
                placeholder={`Search ${searchOption[tableName].selected}`}
                // value={searchValue}
                onChange={e => {
                  // console.log('search value : ', e.target.value, ' , data : ');
                  // console.log('testing : ', {
                  //   ...data,
                  //   [tableName]: data[tableName].filter(item => {
                  //     console.log(
                  //       'selected data : ',
                  //       item[searchOption[tableName].selected].toString()
                  //         .replace('$', '-')
                  //         .match(e.target.value.replace('$', '-'))
                  //     );
                  //     return item[searchOption[tableName].selected].toString()
                  //       .replace('$', '-')
                  //       .match(e.target.value.replace('$', '-'));
                  //   })
                  // });
                  setData({
                    ...data,
                    [tableName]: data[tableName].filter(item =>
                      item[searchOption[tableName].selected]
                        .toString()
                        .toLowerCase()
                        .replace('$', '-')
                        .match(e.target.value.replace('$', '-').toLowerCase())
                    )
                  });
                  setPages({
                    ...pages,
                    [tableName]: {
                      ...pages[tableName],
                      len: data[tableName].filter(item =>
                        item[searchOption[tableName].selected]
                          .toString()
                          .replace('$', '-')
                          .match(e.target.value.replace('$', '-'))
                      ).length
                    }
                  });
                }}
              />
            </Form.Group>
          </Form>
        </div>
      )}
    </div>
  ) : (
    <div></div>
  );
};
export default function AppTable({
  data,
  cbClickConfirm,
  numberOfRow = 50,
  displayTableName = [],
  tableTitle = {},
  brakeLines = false,
  showDropDown = false,
  showSearchBar = false,
  filterDropDownOption = {}
}) {
  // console.log('Table Data  :', data);
  const [dataInitiate, setDataInitiate] = useState(data);

  const [selectedTable, setSelectedTable] = useState('');
  const [show, setShow] = useState(false);

  const scollToRef = useRef({});
  const flag = useRef(false);

  const [selectedScroll, setSelectedScroll] = useState({
    key: Object.keys(data)[0],
    value: Object.keys(data)[0].toUpperCase().slice(0, -6).replaceAll('_', ' ')
  });
  const [sortKey, setSortKey] = useState(
    Object.keys(data).map(table => ({
      [table]: Object.keys(data[table][0]).reduce((acc, elem) => {
        acc[elem] = false;
        return acc;
      }, {})
    }))
  );
  const sortRow = (index, tableName, row, init_data, setDataInitiate) => {
    // console.log('***table Name : ', tableName, ', sorted table : ', init_data);

    let sorted;
    if (sortKey[index][tableName][row]) {
      sorted = init_data[tableName].sort((a, b) =>
        a[row] > b[row] ? 1 : b[row] > a[row] ? -1 : 0
      );
    } else {
      sorted = init_data[tableName].sort((a, b) =>
        b[row] > a[row] ? 1 : a[row] > b[row] ? -1 : 0
      );
    }

    setDataInitiate(pre => ({
      ...pre,
      [tableName]: sorted
    }));

    let temp = sortKey;

    temp[index][tableName] = {
      ...sortKey[index][tableName],
      [row]: !sortKey[index][tableName][row]
    };
    // console.log('testing : ', temp);
    setSortKey(temp);
  };

  const [pages, setPages] = useState(
    Object.keys(data).reduce(
      (previous, tableName) => ({
        ...previous,
        [tableName]: {
          len: data[tableName].length,
          currentRow: numberOfRow,
          selectedNumberOfRow: numberOfRow
        }
      }),
      []
    )
  );

  useEffect(() => {
    setDataInitiate(data);
    setPages(
      Object.keys(data).reduce(
        (previous, tableName) => ({
          ...previous,
          [tableName]: {
            len: data[tableName].length,
            currentRow: numberOfRow,
            selectedNumberOfRow: numberOfRow
          }
        }),
        []
      )
    );
  }, [data]);
  useEffect(() => {
    // console.log(dataInitiate);
  }, [dataInitiate]);

  useEffect(() => {
    flag.current &&
      scollToRef.current[selectedScroll.key].scrollIntoView({
        behavior: 'smooth',
        block: 'start'
      });
  }, [selectedScroll]);

  const [candidateInfo, setCandidateInfo] = useState({
    tableName: null,
    item: null
  });

  const scrollTable = selectedScrolledTableName => {
    // console.log(selectedScrolledTableName, scollToRef);
    scollToRef.current[selectedScrolledTableName.key].scrollIntoView({
      behavior: 'smooth',
      block: 'start'
    });
    flag.current = true;
    setSelectedScroll(selectedScrolledTableName);
  };

  return (
    <div>
      {Object.keys(data).length ? (
        Object.keys(data).map((tableName, index) => (
          <div
            key={index}
            id={tableName}
            ref={el => {
              // console.log(el);
              scollToRef.current[tableName] = el;
            }}
            className={'text-black border my-4 rounded-3'}
          >
            <div>
              <div className="d-flex justify-content-between pt-3">
                <div>
                  {/* <div className="ms-2 fw-medium ps-1">
                    {Object.keys(data).length > 3 && (
                      <div className="d-flex align-items-center">
                        <div className="px-2"> Go To:</div>
                        <DropdownOptionKeyPair
                          setSelected={scrollTable}
                          selected={selectedScroll}
                          listItems={Object.keys(data).map(key => ({
                            key: key,
                            value: key
                              .toUpperCase()
                              .slice(0, -6)
                              .replaceAll('_', ' ')
                          }))}
                        />
                      </div>
                    )}
                  </div> */}
                  <h5 className="px-3 py-2 mx-1 mt-2 text-700 fw-lighter">
                    {tableTitle[tableName]}
                  </h5>
                </div>
                <div className="w-50 my-auto">
                  {
                    <SearchData
                      data={data}
                      showDropDown={showDropDown}
                      showSearchBar={showSearchBar}
                      setData={setDataInitiate}
                      tableName={tableName}
                      setPages={setPages}
                      pages={pages}
                      filterDropDownOption={filterDropDownOption}
                    />
                  }
                </div>
              </div>
              {dataInitiate[tableName]?.length ? (
                <div className="mt-3">
                  <Table responsive striped bordered hover>
                    <thead>
                      <tr
                        className="bg-100"
                        // style={{
                        //   backgroundColor: '#F1F6FB'
                        // }}
                      >
                        {dataInitiate[tableName][0] &&
                          Object.keys(dataInitiate[tableName][0]).map(
                            (th_data, ih) =>
                              th_data !== 'doc_object_id_str' && (
                                <th
                                  key={ih}
                                  className="fw-bold text-700 text-uppercase fs--1 text-nowrap"
                                >
                                  {th_data}{' '}
                                  {/* <Button
                                    onClick={() => {
                                      sortRow(index, tableName, th_data);
                                    }}
                                  > */}
                                  {sortKey[index][tableName][th_data] ? (
                                    <BsArrowDown
                                      className="fw-bold"
                                      onClick={() => {
                                        sortRow(
                                          index,
                                          tableName,
                                          th_data,
                                          dataInitiate,
                                          setDataInitiate
                                        );
                                      }}
                                    />
                                  ) : (
                                    <BsArrowUp
                                      className="fw-bold"
                                      onClick={() => {
                                        sortRow(
                                          index,
                                          tableName,
                                          th_data,
                                          dataInitiate,
                                          setDataInitiate
                                        );
                                      }}
                                    />
                                  )}
                                  {/* </Button> */}
                                </th>
                              )
                          )}
                        {(tableName === 'cards_redeemed_array' ||
                          tableName === 'admin_issued_bonuses_array') && (
                          <th></th>
                        )}
                      </tr>
                    </thead>
                    <tbody>
                      {dataInitiate[tableName]
                        .slice(
                          pages[tableName].currentRow -
                            pages[tableName].selectedNumberOfRow,
                          pages[tableName].currentRow == 'All'
                            ? pages[tableName].len
                            : pages[tableName].currentRow
                        )
                        .map((item, index) => (
                          <tr key={index}>
                            {Object.keys(item).map(
                              (dataKey, indexData) =>
                                dataKey !== 'doc_object_id_str' && (
                                  <td
                                    key={indexData}
                                    className="fw-normal text-700 text-nowrap"
                                  >
                                    {
                                      dataKey.toLowerCase().includes('cost') || dataKey.toLowerCase().includes('flagged total')
                                      ? '$'
                                      : ''
                                    }
                                    {brakeLines ? (
                                      item[dataKey] &&
                                      Object.keys(dataInitiate[tableName][0])
                                        .length > 4 &&
                                      item[dataKey].length > 25 ? (
                                        // ||
                                        // console.log(
                                        //   'testing lenght : ',
                                        //   Object.keys(dataInitiate[tableName][0])
                                        //     .length
                                        // )
                                        <TooltipEx
                                          sData={
                                            item[dataKey].slice(0, 25) + '...'
                                          }
                                        >
                                          {item[dataKey]}
                                        </TooltipEx>
                                      ) : (
                                        item[dataKey]
                                      )
                                    ) : (
                                      item[dataKey]
                                    )}
                                    {dataKey.toLowerCase().includes('time')
                                      ? ' hrs'
                                      : dataKey.toLowerCase().includes('flagged %')
                                      ? '%'
                                      : ''}
                                    {/* {console.log("Testing data : ",dataKey,dataKey.includes("hrs"))} */}
                                  </td>
                                )
                            )}
                            {(tableName === 'cards_redeemed_array' ||
                              tableName === 'admin_issued_bonuses_array') && (
                              <td className="text-black text-center text-nowrap ">
                                {/* This code is for edit function */}
                                {/* <BsPencilSquare
                        className="fs-1 me-2"
                        onClick={() => setShowEdit(true)}
                      /> */}

                                <MdDeleteOutline
                                  className="fs-2 text-danger"
                                  onClick={() => {
                                    setSelectedTable(
                                      tableName
                                        .slice(0, -6)
                                        .split('_')
                                        .reduce(
                                          (con, word) =>
                                            con + word.toUpperCase() + ' ',
                                          ''
                                        ) + ' Table'
                                    );
                                    setShow(true);
                                    setCandidateInfo({
                                      tableName: tableName,
                                      item: item
                                    });
                                  }}
                                />
                              </td>
                            )}
                          </tr>
                        ))}
                    </tbody>
                  </Table>
                  {pages[tableName].len > numberOfRow && (
                    <div className="d-flex align-items-center justify-content-between p-3 mb-2">
                      <div className="d-flex align-items-center flex-fill gap-3 ">
                        <div className="fs--1 text-muted ms-1">
                          {pages[tableName].currentRow -
                            (pages[tableName].selectedNumberOfRow === 'All'
                              ? pages[tableName].len
                              : pages[tableName].selectedNumberOfRow) +
                            1}{' '}
                          to{' '}
                          {pages[tableName].currentRow == 'All'
                            ? pages[tableName].len
                            : pages[tableName].currentRow}{' '}
                          of {pages[tableName].len}
                        </div>
                        <div className="d-flex w-25 align-items-center gap-2 flex-fill">
                          <div className="fs--1 text-black ">
                            Rows per page :
                            {/* <b><br/>{pages[tableName].currentRow} --- {(pages[tableName].currentRow+1) * numberOfRow} </b> */}
                          </div>
                          <DropdownOption
                            className={'w-25'}
                            defaultMenuHigth={
                              ((pages[tableName].len / numberOfRow) | 0) > 7
                                ? false
                                : true
                            }
                            setSelected={item => {
                              setPages({
                                ...pages,
                                [tableName]: {
                                  ...pages[tableName],
                                  selectedNumberOfRow:
                                    item === 'All' ? 'All' : item,
                                  currentRow:
                                    item === 'All' ? pages[tableName].len : item
                                }
                              });
                            }}
                            selected={
                              pages[tableName].selectedNumberOfRow === 'All'
                                ? 'All'
                                : pages[tableName].selectedNumberOfRow
                            }
                            listItems={['All'].concat(
                              Array.from({
                                length:
                                  pages[tableName].len % numberOfRow
                                    ? ((pages[tableName].len / numberOfRow) |
                                        0) +
                                      1
                                    : (pages[tableName].len / numberOfRow) | 0
                              }).map((u, i) => (i + 1) * numberOfRow)
                            )}
                          />
                        </div>
                      </div>
                      <div>
                        {pages[tableName].selectedNumberOfRow !== 'All' && (
                          <div className="d-flex justify-content-end gap-2">
                            <Button
                              disabled={
                                pages[tableName].currentRow -
                                  pages[tableName].selectedNumberOfRow <=
                                0
                              }
                              onClick={() => {
                                setPages({
                                  ...pages,
                                  [tableName]: {
                                    ...pages[tableName],
                                    currentRow:
                                      pages[tableName].currentRow -
                                      pages[tableName].selectedNumberOfRow
                                  }
                                });
                              }}
                            >
                              previous
                            </Button>
                            <Button
                              disabled={
                                pages[tableName].currentRow >
                                pages[tableName].len
                              }
                              onClick={() => {
                                setPages({
                                  ...pages,
                                  [tableName]: {
                                    ...pages[tableName],
                                    currentRow:
                                      pages[tableName].currentRow +
                                      pages[tableName].selectedNumberOfRow
                                  }
                                });
                              }}
                            >
                              Next
                            </Button>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              ) : (
                <div className="text-center my-4">
                  <h4>No Results !!!</h4>
                </div>
              )}
              <CustomModal
                show={show}
                setShow={setShow}
                title={'Delete from ' + selectedTable}
              >
                {
                  <DeleteComponent
                    setShow={setShow}
                    cbClickConfirm={() =>
                      cbClickConfirm(
                        candidateInfo['tableName'],
                        candidateInfo['item']
                      )
                    }
                  />
                }
              </CustomModal>
              {/* Edit modal section */}
              {/* <CustomModal
              show={showEdit}
              setShow={setShowEdit}
              title={'Edit On '+selectedTable}
            >{
              <DeleteComponent setShow={setShow}/>
            }</CustomModal>   */}
            </div>
          </div>
        ))
      ) : (
        <div>No data available !!!</div>
      )}
    </div>
  );
}

import * as ParseUtil from "karma_lib/util/ParseUtil.js";

const USER_IS_OBJECT = true;

export const getLanguage = function() {
    let FORCE_ENGLISH = true;
    let retLang;
    if (FORCE_ENGLISH) {
        retLang = "en";
    } else {
        let decodedParams = {}; //getNativeDecodedParams();
        if (decodedParams["language"]) {
            retLang = decodedParams["language"];
        } else {
            retLang = "en";
        }
    }
    return retLang;
}

export const incTest = function() {
    var curVal = appItemGet1("test_val");
    if (curVal == null) {
        curVal = 1;
    } else {
        curVal = Number(curVal) + 1;
    }
    appItemSet1("test_val", curVal);
    curVal = appItemGet1("test_val");
    
    return curVal;
}

export const getLastBrowserRefreshDate = function() {
    var refreshDateStr = appItemGet1("browser_refresh_date");
    let dateObj;
    if (refreshDateStr) {
        dateObj = new Date(refreshDateStr);
    } else {
        dateObj = null;
    }
    return dateObj;
}

export const setLastBrowserRefreshDate = function(browserRefreshDate) {
    let browserISOStr = browserRefreshDate.toISOString();
    appItemSet1("browser_refresh_date", browserISOStr);
}

export const getIsAdmin = function() {
    let userInfo = ParseUtil.jsonParse(appItemGetUser("user_info"));
    let retValue;
    if (userInfo) {
        retValue = userInfo["isAdmin"];
    } else {
        retValue = false;
    }
    return retValue;
}

export const getSourceChoiceArray = function() {
    let userInfo = ParseUtil.jsonParse(appItemGetUser("user_info"));
    let retValue;
    if (userInfo) {
        retValue = userInfo["sourceChoiceArray"];
    } else {
        retValue = [];
    }
    return retValue;
}

export const getUserSourceDisplay = function() {
    let userInfo = ParseUtil.jsonParse(appItemGetUser("user_info"));
    let retValue;
    if (userInfo) {
        retValue = userInfo["sourceDisp"];
    } else {
        retValue = ""; // Should not occur.
    }
    return retValue;
}

export const getUserEmail = function() {
    let userInfo = ParseUtil.jsonParse(appItemGetUser("user_info"));
    let retValue;
    if (userInfo) {
        retValue = userInfo["email"];
    } else {
        retValue = ""; // Should not occur.
    }
    return retValue;
}

export const getDfSessionToken = function() {
    let userInfo = ParseUtil.jsonParse(appItemGetUser("user_info"));
    let retValue;
    if (userInfo) {
        retValue = userInfo["dfSessionToken"];
    } else {
        retValue = null;
    }
    return retValue;
}

export const setUser = function(dsbUser, isRemember) {
    clearData();
    let userInfo = {
        "dfSessionToken" : dsbUser["dfSessionToken"],
        "sourceDisp": dsbUser["sourceDisp"],
        "email": dsbUser["email"],
        "isAdmin": dsbUser["isAdmin"],
        "sourceChoiceArray": dsbUser["sourceChoiceArray"]
    };
    let itemValue = ParseUtil.jsonStringify(userInfo);
    appItemSetUser("user_info", itemValue, isRemember);
}

export const setConfirmationTexts = function(title, msg) {
    appItemSet1("confirmation_title", title);
    appItemSet1("confirmation_msg", msg);
}

export const getConfirmationTexts = function() {
    let title = appItemGet1("confirmation_title");
    let msg = appItemGet1("confirmation_msg");
    return {
        "title": title,
        "msg": msg
    }
}

export const removeConfirmationTexts = function() {
    appItemRemove1("confirmation_title");
    appItemRemove1("confirmation_msg");
}

export const setSelectedUtmSourceEntry = function(preValue) {
    let value = ParseUtil.jsonStringify(preValue);
    appItemSet1("selected_utm_source", value);
}

export const getSelectedUtmSrcEntry = function() {
    let retValue = ParseUtil.jsonParse(appItemGet1("selected_utm_source"));
    return retValue;
}

export const removeSelectedUtmSrcEntry = function() {
    appItemRemove1("selected_utm_source");
}

export const getSelectedUtmSource = function() {
    let retValue;
    let retObj = getSelectedUtmSrcEntry();
    if (retObj) {
        retValue = retObj["entry_id"]
    } else {
        retValue = null;
    }
    return retValue;
}

export const handleSaveLoginCache = function() {
    let userInfo = appItemGetUserLocal("user_info");
    let isRemember = userInfo != null;
    let email = getUserEmail();
    clearData();
    if (isRemember) {
        setLoginIsRemember(isRemember);
        setLoginEmail(email);
    }
}

export const setLoginIsRemember = function(isRemember) {
    appItemSet1("login_is_remember", isRemember);
}

export const setLoginEmail = function(email) {
    appItemSet1("login_email", email);
}

export const getLoginIsRemember = function() {
    let rawRemember = appItemGet1("login_is_remember");
    let isRemember = rawRemember == "true" ? true : false;
    return isRemember;
}

export const getLoginEmail = function() {
    let rawEmail = appItemGet1("login_email");
    let email = rawEmail ? rawEmail : "";
    return email;
}

export const clearData = function() {
    sessionStorage.clear();
    localStorage.clear();
}

function appItemSet1(itemKey, itemValue) {
    sessionStorage.setItem(itemKey, itemValue);
}

function appItemGet1(itemKey) {
    let itemValue = sessionStorage.getItem(itemKey);
    return itemValue;
}

function appItemRemove1(itemKey) {
    sessionStorage.removeItem(itemKey);
}

function appItemSetUser(itemKey, itemValue, isRemember) {
    if (isRemember) {
        localStorage.setItem(itemKey, itemValue);    
    } else {
        sessionStorage.setItem(itemKey, itemValue);
    }
}

function appItemGetUser(itemKey) {
    let preValue = localStorage.getItem(itemKey);
    if (preValue == null) {
        preValue = sessionStorage.getItem(itemKey);
    }
    let itemValue = preValue;
    return itemValue;
}

function appItemGetUserLocal(itemKey) {
    let itemValue = localStorage.getItem(itemKey);
    return itemValue;
}
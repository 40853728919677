import React, { useContext, useEffect, useRef, useState } from 'react';
import { Button } from 'react-bootstrap';
import * as MyAlertDialog2 from 'karma_lib/dialog/MyAlertDialog2.js';
import * as PageUtil from '../util/PageUtil.js';
import * as CampaignReportRequest from 'http_request/CampaignReportRequest.js';
import * as ArrayUtil from 'karma_lib/util/ArrayUtil.js';
import * as TimeUtil from 'karma_lib/util/TimeUtil.js';
import { useLocation, useNavigate } from 'react-router-dom';

import DropdownOptionKeyPair from 'util/DropdownOptionKeyPair';
import DatePicker from 'react-datepicker';
import AppTable from 'util/AppTableV4.js';
import { BsInfoCircle } from 'react-icons/bs';
import TooltipEx from 'util/TooltipEx.js';
import BasicSearchForm from '../util/BasicSearchForm.js';
import { DataContext } from 'context/Context.js';


let sAlertDialogObject2 = MyAlertDialog2.createDialog();

export default function CampaignPage() {
  const { pathname } = useLocation();
  const { setCurrentUrl } = useContext(DataContext);
  const [applicationChoiceArray, setApplicationChoiceArray] = useState(PageUtil.getPlaceholderChoiceArray());
  const [app, setApp] = useState(applicationChoiceArray[0]);

  const [filterDropDownOption] = useState({
    campaign_stats_array: [
      'Campaign'
      // 'Installs',
      // 'Conver',
      // 'Conversions',
      // 'Flagged Total',
      // 'Total Cost'
    ]
  });
  const [from, setFrom] = useState(PageUtil.createDefaultStartDate(new Date()));
  const [to, setTo] = useState(PageUtil.createDefaultEndDate(new Date()));
  const [deviceType, setDeviceType] = useState({
    key: 'all',
    value: 'All'
  });
  const [deviceTypeList] = useState([
    {
      key: 'all',
      value: 'All'
    },
    {
      key: 'android',
      value: 'Android'
    },
    {
      key: 'ios',
      value: 'iOS'
    }
  ]);
  const [reportData, setReportData] = useState({});
  const [modalLoading, setModalLoading] = useState(false);
  const [unused, setForceUpdateObject] = useState({ dummy_key: 'dummy_val' });
  const navigate = useNavigate();

  function forceRefreshView() {
    setForceUpdateObject({ dummy_key: 'dummy_val' });
  }

  let makeRequest = () => {
    let queryApplicationArray = [app['entry_id']];

    let prePlatform = deviceType['key'];
    let queryPlatformArray = [];
    if (prePlatform == 'all') {
      let tempArray = ArrayUtil.arrayPluck(deviceTypeList, 'key');
      queryPlatformArray = PageUtil.createQueryArrayAll2(tempArray);
    } else {
      queryPlatformArray.push(prePlatform);
    }

    let startDate = from;
    let endDate = to;
    setModalLoading(true);
    CampaignReportRequest.fetchCampaignReport(
      queryApplicationArray,
      queryPlatformArray,
      startDate,
      endDate,
      (errorInfo, responseInfo) => {
        if (errorInfo) {
          setModalLoading(false);
          PageUtil.handleErrorResponse(
            errorInfo,
            navigate,
            forceRefreshView,
            sAlertDialogObject2
          );
        } else {
          // console.log(responseInfo['resp_data']);
          setReportData(responseInfo['resp_data']);
          console.log(responseInfo['resp_data']);
          setModalLoading(false);
        }
      }
    );
  };

  React.useEffect(() => {
    setModalLoading(true);
    CampaignReportRequest.getCampaignReportInputs(
      (errorInfo, responseInfo) => {
        if (errorInfo) {
            setModalLoading(false);
            PageUtil.handleErrorResponse(
              errorInfo,
              navigate,
              forceRefreshView,
              sAlertDialogObject2
            );
          } else {
            setModalLoading(false);
            let choiceArray;
            if (responseInfo["application_choice_array"].length > 0) {    
                choiceArray = responseInfo["application_choice_array"];
            } else {
                choiceArray = PageUtil.getPlaceholderChoiceArray();
            }
            setApplicationChoiceArray(choiceArray);
            setApp(choiceArray[0]);
            forceRefreshView();
          }
      }
    );
    setCurrentUrl(pathname);
  }, []);

  return (
    <div
      className="container p-0"
      // style={{
      //   width: '1200px'
      // }}
    >
      <div className="shadow bg-white text-black font-sans-serif rounded-3 pt-4">
        {/* <div className="border border-top-0 border-bottom-0 border-start-0 border-end-0 p-4"> 
          <h4 className="text-primary p-0 m-0">
            Campaign Report 
          </h4>
        </div>  */}
        <BasicSearchForm
          app={app}
          setApp={setApp}
          appList={applicationChoiceArray}
          deviceType={deviceType}
          setDeviceType={setDeviceType}
          deviceTypeList={deviceTypeList}
          from={from}
          setFrom={setFrom}
          to={to}
          setTo={setTo}
          cbOnClick={makeRequest}
        />
        {/* <div className="d-flex flex-wrap justify-content-lg-between p-4 border border-top-0 border-bottom-0 border-start-0 border-end-0 bg-light rounded-3 ms-4 me-4 mb-4 bg-200">
          {app && (
            <div className="w-xl-25 w-100 gap-2 py-2 d-flex">
              <DropdownOptionKeyPair
                setSelected={item => {
                  setApp(item);
                }}
                selected={app}
                className={'w-xl-50 w-50'}
                listItems={reportData.application_choice_array}
                keyName={{ key: 'entry_id', value: 'entry_disp' }}
              />
              <DropdownOptionKeyPair
                setSelected={item => {
                  setDeviceType(item);
                }}
                className={'w-xl-50 w-50'}
                selected={deviceType}
                listItems={deviceTypeList}
                // keyName={{ key: 'key', value: 'value' }}
              />
            </div>
          )}
          <div className="d-flex gap-2 w-xl-50 w-100 py-2">
            <div className=" d-flex align-items-center w-xl-50 w-50">
              <div className="my-auto me-2 fw-medium">From :</div>
              <div className=''>
                <DatePicker
                  showIcon
                  className="p-1 ps-2 border border-black rounded-1"
                  selected={from}
                  onChange={date => setFrom(date)}
                />
              </div>
            </div>
            <div className="d-flex align-items-center">
              <div className="my-auto me-2 fw-medium">To :</div>
              <div className=''>
                <DatePicker
                  showIcon
                  className="p-1 ps-2 border border-black rounded-1"
                  selected={to}
                  onChange={date => setTo(date)}
                />
              </div>
            </div>
          </div>
          <div className="py-2 w-xl-auto w-100">
              <Button
                className="w-xl-auto w-100"
                // onClick={() => getUserInfo1()}
              >
                Submit
              </Button>
            </div>
        </div> */}
        {
          reportData&&<div>
            {reportData.campaign_stats_total && (
              <div className="row row-cols-2 row-cols-sm-2 row-cols-md-3 row-cols-lg-5 row-cols-xl-5 pt-3 pb-2 border-bottom border-top mx-4 bg-100 mt-5 ">
                <div align="center" className="col">
                  <div className="fs--1 fw-medium text-uppercase text-600">
                    Installs
                  </div>
                  <h2 className="fw-bold">
                    {reportData.campaign_stats_total['Installs']}
                  </h2>
                </div>
                <div align="center" className="col">
                  <div className="fs--1 fw-medium text-uppercase text-600">
                    Conversions
                  </div>
                  <h2 className="fw-bold">
                    {reportData.campaign_stats_total['Conversions']}
                  </h2>
                </div>
                <div align="center" className="col">
                  <div className="fs--1 fw-medium text-uppercase text-600">
                    Total Cost
                  </div>
                  <h2 className="fw-bold">
                    {reportData.campaign_stats_total['Total Cost']}
                  </h2>
                </div>
                <div align="center" className="col">
                  <div className="fs--1 fw-medium d-flex justify-content-center text-uppercase text-600">
                    <div
                      className="me-1"
                      style={{
                        marginTop: '1.5px'
                      }}
                    >
                      Flagged Total
                    </div>
                    <div>
                      <TooltipEx
                        sData={<BsInfoCircle className="fw-bold fs--1" />}
                      >
                        These are conversions that we have flagged as
                        suspicious. The actual fraud total will be determined at
                        the beginning of each month when finalizing monthly
                        numbers.
                      </TooltipEx>
                    </div>
                  </div>
                  <h2 className="fw-bold">
                    {reportData.campaign_stats_total['Flagged Total']}
                  </h2>
                </div>
                <div align="center" className="col">
                  <div className="fs--1 fw-medium text-uppercase text-600">
                    Flagged %
                  </div>
                  <h2 className="fw-bold">
                    {reportData.campaign_stats_total['Flagged %']}
                  </h2>
                </div>
              </div>
            )}
            <div className="mx-4 pb-4">
              <div>
                {reportData.campaign_stats_array && (
                  <AppTable
                    tableTitle={{
                      campaign_stats_array:
                        'Campaign Report ' +
                        PageUtil.createDateRangeTitle(
                          reportData['reportStartDate'],
                          reportData['reportEndtDate']
                        )
                    }}
                    data={{
                      campaign_stats_array: reportData.campaign_stats_array
                      // testing: [{ -- if you need a second table.
                      //   name:"murshed2",
                      //   address :'asdafsdf1',
                      //   cost:'23'
                      // },{
                      //   name:"murshed1",
                      //   address :'asdafsdf2',
                      //   cost:'235'
                      // },{
                      //   name:"murshed4",
                      //   address :'asdafsdf',
                      //   cost:'2'
                      // },]
                    }}
                    showSearchBar={true}
                    numberOfRow={25}
                    displayTableName={['']}
                    // brakeLines={false}
                    filterDropDownOption={filterDropDownOption}
                  />
                )}
              </div>
            </div>
          </div>
        }
      </div>
      {sAlertDialogObject2['elem_view']}
      {modalLoading ? PageUtil.createLoadingIndicator() : null}
    </div>
  );
}


/* eslint-disable no-useless-concat */
/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */

// export const createStyles1 = function(styleObjInfo) {
//     const styles = ReactNative.StyleSheet.create(styleObjInfo);
//     return styles;
// }

export const createStyle1 = function(styleObjInfo) {
    return styleObjInfo;
}

// export const initWithShadowFields = function(styleObj) {
//     addShadowFields(styleObj);
//     return styleObj;
// }

export const createStyleText = function(styleObjInfo) {
    // fontFamily: circular_std_black,
    // fontSize: 24,
    // color: "#888888FF",
    return styleObjInfo;
}

const STYLE_SHADOW = createStyle1({
    shadowColor: "#000000FF",
    shadowOffset: {
        width: "0px",
        height: "2px",
    },
    shadowOpacity: 0.25,
    shadowRadius: "4px"                   
});

export const addShadowFields = function(styleObj) {
    const keysArray = Object.keys(STYLE_SHADOW);
    for (var index = 0; index < keysArray.length; index++) {
        var curKey = keysArray[index];
        styleObj[curKey] = STYLE_SHADOW[curKey];
    }
}

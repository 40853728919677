// import React from 'react';
// import { Card } from 'react-bootstrap';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { Link } from 'react-router-dom';
// import { faHome } from '@fortawesome/free-solid-svg-icons';

// const Error404 = () => {
//   return (
//     <div
//       className="d-flex justify-content-center align-items-center"
//       style={{
//         // height: '100vh'
//       }}
//     >
//       <Card
//         className="p-5"
//         style={{
//           width: '480px'
//         }}
//       >
//         <div className="mb-3">
//           <div>
//             <h3 className="text-700 fw-bold fs-2 mb-3">Contact Us</h3>
//           </div>
//           <div>
//             <h5>Opens email client</h5>
//             <div>
//               <div className='my-2'>
//                 <span className='fw-bold'>To : </span>
//                 <span className='text-black'>adops@appkarma.io</span>
//               </div>
//               <div>
//                 <span className='fw-bold'>Subject : </span>
//                 <span className='text-black'>UA Partner Dashboard inquiry.</span>
//               </div>
//             </div>
//           </div>
//         </div>
//       </Card>
//     </div>
//   );
// };

// export default Error404;

import React from 'react';
import { Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { faHome } from '@fortawesome/free-solid-svg-icons';

const Error404 = () => {
  return (
    <Card className="text-center">
      <Card.Body className="p-5">
        <div className="display-1 text-300 fs-error">404</div>
        <p className="lead mt-4 text-800 font-sans-serif fw-semi-bold">
          The page you're looking for is not found.
        </p>
        <hr />
        <p>
          Make sure the address is correct and that the page hasn't moved. If
          you think this is a mistake,
          <a href="mailto:adops@appkarma.io" className="ms-1">
            contact us
          </a>
          .
        </p>
        <Link className="btn btn-primary btn-sm mt-3" to="/campaign_report">
          <FontAwesomeIcon icon={faHome} className="me-2" />
          Take me home
        </Link>
      </Card.Body>
    </Card>
  );
};

export default Error404;

import Divider from 'components/common/Divider';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { BsBox } from 'react-icons/bs';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as LoginRequest from 'http_request/LoginRequest.js';
import * as MyAlertDialog2 from 'karma_lib/dialog/MyAlertDialog2.js';
import * as StorageUtil from 'karma_lib/util/StorageUtil.js';
import * as PageUtil from 'util/PageUtil.js';
import { DataContext } from 'context/Context';
import * as ParseUtil from 'karma_lib/util/ParseUtil.js';
import CryptoJS from 'crypto-js';
let sAlertDialogObject2 = MyAlertDialog2.createDialog();
const LoginForm = ({ hasLabel, layout }) => {
  // State

  const [formData, setFormData] = useState({
    email: '',
    password: '',
    remember: false
  });
  const [modalLoading, setModalLoading] = useState(false);
  const [unused, setForceUpdateObject] = useState({ dummy_key: 'dummy_val' });
  const { currentUrl } = useContext(DataContext);

  function forceRefreshView() {
    setForceUpdateObject({ dummy_key: 'dummy_val' });
  }
  const navigate = useNavigate(); // https://artwilton.medium.com/adding-a-link-to-a-bootstrap-button-with-react-router-57d2f6197588
  // https://stackoverflow.com/questions/62861269/attempted-import-error-usehistory-is-not-exported-from-react-router-dom
  // Handler
  const handleSubmit = e => {
    e.preventDefault();
    setModalLoading(true);
    LoginRequest.sendLogin(
      formData.email,
      formData.password,
      (errorInfo, responseInfo) => {
        if (errorInfo) {
          setModalLoading(false);
          MyAlertDialog2.showDialogError(
            errorInfo['errorMsg'],
            sAlertDialogObject2,
            null
          );
          forceRefreshView();
        } else {
          setModalLoading(false);
          let isRemember = formData['remember'];
          StorageUtil.setUser(responseInfo['dashboard_user'], isRemember);
          navigate('/campaign_report');
        }
      }
    );
    // toast.success(`Logged in as ${formData.email}`, {
    //   theme: 'colored'
    // });
  };

  const handleFieldChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  useEffect(() => {
      setFormData({
        ...formData,
        email: StorageUtil.getLoginEmail(),
        remember: StorageUtil.getLoginIsRemember()
      });
  }, []);
  // useEffect(() => {}, [formData]);

  return (
    <div
      className="d-flex justify-content-center align-items-center"
      style={{
        height: '100vh'
      }}
    >
      <Card
        className="p-5"
        style={{
          width: '480px'
        }}
      >
        <div className="d-flex align-items-center mb-3">
          <h4 className="text-700 fw-bold fs-3 m-0">Log in</h4>
        </div>
        <Form className="mt-3" onSubmit={handleSubmit}>
          <Form.Group className="mb-3">
            {hasLabel && <Form.Label>Email address</Form.Label>}
            <Form.Control
              placeholder={!hasLabel ? 'Email address' : ''}
              value={formData.email}
              name="email"
              onChange={handleFieldChange}
              type="email"
            />
          </Form.Group>

          <Form.Group className="mb-3">
            {hasLabel && <Form.Label>Password</Form.Label>}
            <Form.Control
              placeholder={!hasLabel ? 'Password' : ''}
              value={formData.password}
              name="password"
              onChange={handleFieldChange}
              type="password"
            />
          </Form.Group>

          <Row className="justify-content-between align-items-center">
            <Col xs="auto">
              <Form.Check type="checkbox" id="rememberMe" className="mb-0">
                <Form.Check.Input
                  type="checkbox"
                  name="remember"
                  checked={formData.remember}
                  onChange={e => {
                    setFormData({
                      ...formData,
                      remember: e.target.checked
                    });
                  }}
                />
                <Form.Check.Label className="mb-0 text-700">
                  Remember me
                </Form.Check.Label>
              </Form.Check>
            </Col>

            <Col xs="auto">
              <Link className="fs--1 mb-0" to={`/forgot_password`}>
                Forgot Password?
              </Link>
            </Col>
          </Row>

          <Form.Group>
            <Button
              type="submit"
              color="primary"
              className="mt-3 w-100"
              disabled={!formData.email || !formData.password}
            >
              Log in
            </Button>
          </Form.Group>

          {/* <Divider className="mt-4">or log in with</Divider> */}

          {/* <SocialAuthButtons /> */}
        </Form>
      </Card>
      {sAlertDialogObject2['elem_view']}
      {modalLoading ? PageUtil.createLoadingIndicator() : null}
    </div>
  );
};

LoginForm.propTypes = {
  layout: PropTypes.string,
  hasLabel: PropTypes.bool
};

LoginForm.defaultProps = {
  layout: 'simple',
  hasLabel: false
};

export default LoginForm;

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import { Button, Card, Form } from "react-bootstrap";
import classNames from "classnames";
import * as PasswordResetChangeRequest from "http_request/PasswordResetChangeRequest.js";
import { BsBox } from "react-icons/bs";
import * as MyAlertDialog2 from "karma_lib/dialog/MyAlertDialog2.js";
import * as PageUtil from "util/PageUtil.js";
import * as UrlUtil from "util/UrlUtil.js";
import { Link, useNavigate } from "react-router-dom";
import * as StorageUtil from "karma_lib/util/StorageUtil.js";

let sAlertDialogObject2 = MyAlertDialog2.createDialog();
const PasswordResetForm = ({ hasLabel }) => {
  // State
  const [formData, setFormData] = useState({
    password: "",
    confirmPassword: ""
  });
  const navigate = useNavigate();
  const [modalLoading, setModalLoading] = useState(false);
  const [unused, setForceUpdateObject] = useState({ dummy_key: "dummy_val" });
  function forceRefreshView() {
    setForceUpdateObject({ dummy_key: "dummy_val" });
  }
  // Handler
  const handleSubmit = e => {
    e.preventDefault();
    setModalLoading(true);
    let locObj = UrlUtil.getLocationUrlData();
    let hasVal = locObj["hash"];
    PasswordResetChangeRequest.changeResetPassword(hasVal, formData["password"], 
    (errorInfo, responseInfo) => {
        if (errorInfo) {
          setModalLoading(false);
          MyAlertDialog2.showDialogError(
            errorInfo["errorMsg"],
            sAlertDialogObject2,
            null
          );
          forceRefreshView();
        } else {
          setModalLoading(false);
          StorageUtil.setConfirmationTexts(responseInfo["confirmationTitle"], responseInfo["confirmationMsg"]);
          navigate("/password-confirmation-message");
        }
    });
  };

  const handleFieldChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };
  const [Matched, setMachted] = useState(false);
  useEffect(() => {
    if (formData.confirmPassword.length) {
      if (formData.password === formData.confirmPassword) {
        setMachted(false);
      } else {
        setMachted(true);
      }
    }else{
      setMachted(false)
    }
  }, [formData]);
  return (
    <div
      className="d-flex justify-content-center align-items-center"
      style={{
        height: "100vh"
      }}
    >
      <Card
        className="p-5"
        style={{
          width: "480px"
        }}
      >
        <div className="d-flex align-items-center mb-3">
          <h4 className="text-700 fw-bold fs-3 m-0">Password Reset</h4>
        </div>
        <Form
          className={classNames("mt-3", { "text-left": hasLabel })}
          onSubmit={handleSubmit}
        >
          <Form.Group className="mb-3">
            {hasLabel && <Form.Label>New Password</Form.Label>}
            <Form.Control
              placeholder={!hasLabel ? "New Password" : ""}
              value={formData.password}
              name="password"
              onChange={handleFieldChange}
              type="password"
            />
          </Form.Group>

          <Form.Group className="mb-3">
            {hasLabel && <Form.Label>Confirm Password</Form.Label>}
            <Form.Control
              placeholder={!hasLabel ? "Confirm Password" : ""}
              value={formData.confirmPassword}
              name="confirmPassword"
              onChange={handleFieldChange}
              type="password"
            />
          </Form.Group>
          {Matched && (
            <div className="text-danger fs--1 mb-2">Password Not Matched</div>
          )}
          <Button
            type="submit"
            className="w-100"
            disabled={!formData.password || !formData.confirmPassword || Matched}
          >
            Set Password
          </Button>
        </Form>
      </Card>
      {sAlertDialogObject2["elem_view"]}
      {modalLoading ? PageUtil.createLoadingIndicator() : null}
    </div>
  );
};

PasswordResetForm.propTypes = {
  hasLabel: PropTypes.bool
};

export default PasswordResetForm;

import React, { useContext, useEffect, useRef, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { BsArrowUpRightSquare, BsPencil } from 'react-icons/bs';
import Loader from 'util/Loader';
import AppTable from 'util/AppTableV4';
import { BsInfoCircle } from 'react-icons/bs';
import TooltipEx from 'util/TooltipEx.js';
import DropdownOption from 'util/DropdownOption';
import CustomModal from 'util/CustomModal';
import ak_100 from '../asset/image/ak_100.png';
import ck_100 from '../asset/image/ck_100.png';
import pk_100 from '../asset/image/pk_100.png';
import * as MyAlertDialog2 from 'karma_lib/dialog/MyAlertDialog2.js';
import * as RequestUtil from 'http_request/RequestUtil.js';
import * as PageUtil from '../util/PageUtil.js';
import * as ConversionReportRequest from 'http_request/ConversionReportRequest.js';
import * as ArrayUtil from 'karma_lib/util/ArrayUtil.js';
import InputDropdownOption from 'util/InputDropDown';
import ClipboardCopy from 'util/ClipboardCopy';
import * as TimeUtil from 'karma_lib/util/TimeUtil.js';
import { useLocation, useNavigate } from 'react-router-dom';
import DropdownOptionKeyPair from 'util/DropdownOptionKeyPair';
import DatePicker from 'react-datepicker';
import BasicSearchForm from '../util/BasicSearchForm';
import { DataContext } from 'context/Context';

let sAlertDialogObject2 = MyAlertDialog2.createDialog();

export default function ConversionPage() {
  const { pathname } = useLocation();
  const { setCurrentUrl } = useContext(DataContext);
  const [modalLoading, setModalLoading] = useState(false);
  const [unused, setForceUpdateObject] = useState({ dummy_key: 'dummy_val' });
  const navigate = useNavigate();

  function forceRefreshView() {
    setForceUpdateObject({ dummy_key: 'dummy_val' });
  }
  const [conversion, setConversion] = useState({});
  const [applicationChoiceArray, setApplicationChoiceArray] = useState(PageUtil.getPlaceholderChoiceArray());
  const [app, setApp] = useState(applicationChoiceArray[0]);
  
  const [from, setFrom] = useState(PageUtil.createDefaultStartDate(new Date()));
  const [to, setTo] = useState(PageUtil.createDefaultEndDate(new Date()));
  const [deviceType, setDeviceType] = useState({
    key: 'all',
    value: 'All'
  });
  const [deviceTypeList] = useState([
    {
      key: 'all',
      value: 'All'
    },
    {
      key: 'android',
      value: 'Android'
    },
    {
      key: 'ios',
      value: 'iOS'
    }
  ]);
  const [filterDropDownOption] = useState({
    conversions_array: [
      'Campaign',
      'Publisher ID',
      'Conversion Goal',
      'Click ID',
      'Join IP',
      'Device ID',
      'Country'
    ]
  });

  let makeRequest = () => {
    let queryApplicationArray = [app['entry_id']];

    let prePlatform = deviceType['key'];
    let queryPlatformArray = [];
    if (prePlatform == 'all') {
      let tempArray = ArrayUtil.arrayPluck(deviceTypeList, 'key');
      queryPlatformArray = PageUtil.createQueryArrayAll2(tempArray);
    } else {
      queryPlatformArray.push(prePlatform);
    }

    let startDate = from;
    let endDate = to;
    setModalLoading(true);
    ConversionReportRequest.fetchConversionReport(
      queryApplicationArray,
      queryPlatformArray,
      startDate,
      endDate,
      (errorInfo, responseInfo) => {
        if (errorInfo) {
          setModalLoading(false);
          PageUtil.handleErrorResponse(
            errorInfo,
            navigate,
            forceRefreshView,
            sAlertDialogObject2
          );
        } else {
          setConversion(responseInfo['resp_data']);
          setModalLoading(false);
        }
      }
    );
  };

  React.useEffect(() => {
    setModalLoading(true);
    ConversionReportRequest.getConversionReportInputs(
        (errorInfo, responseInfo) => {
        if (errorInfo) {
            setModalLoading(false);
            PageUtil.handleErrorResponse(
                errorInfo,
                navigate,
                forceRefreshView,
                sAlertDialogObject2
            );
            } else {
                setModalLoading(false);
                let choiceArray;
                if (responseInfo["application_choice_array"].length > 0) {    
                    choiceArray = responseInfo["application_choice_array"];
                } else {
                    choiceArray = PageUtil.getPlaceholderChoiceArray();
                }
                setApplicationChoiceArray(choiceArray);
                setApp(choiceArray[0]);
                forceRefreshView();
            }
        }
    );
    setCurrentUrl(pathname);
  }, []);

  return (
    <React.Fragment>
      <div
        className="mx-auto container p-0"
        // style={{
        //   width: '1200px'
        // }}
      >
        {/* <div className="text-end me-4 my-2">murshed@gmail.com</div> */}
        <div className="shadow bg-white text-black font-sans-serif rounded-3 pt-4">
          {/* <div className="border border-top-0 border-start-0 border-end-0 p-4">
            <h4 className="text-primary p-0 m-0">
              Conversion (03/12/22 - 12/12/23)
            </h4>
          </div> */}
          <BasicSearchForm
            app={app}
            setApp={setApp}
            appList={applicationChoiceArray}
            deviceType={deviceType}
            setDeviceType={setDeviceType}
            deviceTypeList={deviceTypeList}
            from={from}
            setFrom={setFrom}
            to={to}
            setTo={setTo}
            cbOnClick={makeRequest}
          />
          {/* <div className="d-flex flex-wrap justify-content-lg-between p-4 border border-top-0 border-bottom-0 border-start-0 border-end-0 bg-light rounded-3 ms-4 me-4 mb-4  bg-200">
            {app && (
              <div className="w-xl-25 w-100 gap-2 py-2 d-flex">
                <DropdownOptionKeyPair
                  setSelected={item => {
                    setApp(item);
                  }}
                  className={'w-xl-50 w-50'}
                  selected={app}
                  listItems={applicationChoiceArray}
                  keyName={{ key: 'entry_id', value: 'entry_disp' }}
                />
                <DropdownOptionKeyPair
                  setSelected={item => {
                    setDeviceType(item);
                  }}
                  className={'w-xl-50 w-50'}
                  selected={deviceType}
                  listItems={deviceTypeList}
                  // keyName={{ key: 'key', value: 'value' }}
                />
              </div>
            )}
            <div className="d-flex gap-2 w-xl-50 w-100 py-2">
              <div className=" d-flex align-items-center w-xl-50 w-50">
                <div className="my-auto me-2 fw-medium">From :</div>
                <div>
                  <DatePicker
                    showIcon
                    className="p-1 ps-2 border border-black rounded-1"
                    selected={from}
                    onChange={date => setFrom(date)}
                  />
                </div>
              </div>
              <div className=" d-flex align-items-center">
                <div className="my-auto me-2 fw-medium">To :</div>
                <div>
                  <DatePicker
                    showIcon
                    className="p-1 ps-2 border border-black rounded-1"
                    selected={to}
                    onChange={date => setTo(date)}
                  />
                </div>
              </div>
            </div>
            <div className="py-2 w-xl-auto w-100">
              <Button
                className="w-xl-auto w-100"
                onClick={() => {
                  makeRequest();
                }}
              >
                Submit
              </Button>
            </div>
          </div> */}
          {conversion && (
            <div>
              {conversion.conversions_stats_total && (
                <div className="row pt-3 pb-2 border-bottom border-top mx-4 bg-100 mt-5">
                  <div align="center" className="col-6 col-lg-3">
                    <div className="fs--1 fw-medium text-uppercase text-600">
                      Conversions
                    </div>
                    <h2 className="fw-bold">
                      {conversion.conversions_stats_total.Conversions}
                    </h2>
                  </div>
                  <div align="center" className="col-6 col-lg-3">
                    <div className="fs--1 fw-medium text-uppercase text-600">
                      Total Cost
                    </div>
                    <h2 className="fw-bold">
                      {conversion.conversions_stats_total['Total Cost']}
                    </h2>
                  </div>
                  <div align="center" className="col-6 col-lg-3">
                    <div className="fs--1 fw-medium d-flex justify-content-center text-uppercase text-600">
                      <div
                        className="me-1"
                        style={{
                          marginTop: '1.5px'
                        }}
                      >
                        Flagged Total
                      </div>
                      <div>
                        <TooltipEx
                          sData={<BsInfoCircle className="fw-bold fs--1" />}
                        >
                          These are conversions that we have flagged as
                          suspicious. The actual fraud total will be determined
                          at the beginning of each month when finalizing monthly
                          numbers.
                        </TooltipEx>
                      </div>
                    </div>
                    <h2 className="fw-bold">
                      {conversion.conversions_stats_total['Flagged Total']}
                    </h2>
                  </div>
                  <div align="center" className="col-6 col-lg-3">
                    <div className="fs--1 fw-medium text-uppercase text-600">
                      Flagged %
                    </div>
                    <h2 className="fw-bold">
                      {conversion.conversions_stats_total['Flagged %']}
                    </h2>
                  </div>
                </div>
              )}
              <div className="mx-4 pb-4">
                <div>
                  {conversion.conversions_array && (
                    <AppTable
                      data={{
                        conversions_array: conversion.conversions_array
                      }}
                      tableTitle={{
                        conversions_array:
                          'Conversion Report ' +
                          PageUtil.createDateRangeTitle(
                            conversion['reportStartDate'],
                            conversion['reportEndtDate']
                          )
                      }}
                      showSearchBar={true}
                      showDropDown={true}
                      numberOfRow={100}
                      displayTableName={['']}
                      filterDropDownOption={filterDropDownOption}
                    />
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      {sAlertDialogObject2['elem_view']}
      {modalLoading ? PageUtil.createLoadingIndicator() : null}
    </React.Fragment>
  );
}

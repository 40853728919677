export const concatArray = function(arr1, arr2) {
    var retArray = arr1.concat(arr2);
    return retArray;
}

export const createCopyArray = function(arr1) {
    var retArray = Array.from(arr1);
    return retArray;
}

export const arrayPluck = function(array, key) {
    return array.map(function(obj) {
        return obj[key];
    });
}

export const createStringFromArray1 = function(inputArray, separator) {
    var retStr = "";
    for (var index = 0; index < inputArray.length; index++) {
        var value = inputArray[index];
        if (index == inputArray.length - 1) {
            retStr += value;
        } else {
            retStr += value + separator;
        }
    }
    return retStr;
}

export const removeAtIndex = function(index, inputArray) {
    let tempArray = createCopyArray(inputArray);
    let newArray = concatArray(tempArray.slice(0, index), tempArray.slice(index + 1));
    return newArray;
}

export const createMapFromArray1 = function(inputArray) {
    let lookupMap = {};
    for (let index = 0; index < inputArray.length; index++) {
        let value = inputArray[index];
        lookupMap[value] = true;
    }
    return lookupMap;
}

export const createMapFromArray2 = function(inputArray, keyName) {
    let lookupMap = {};
    for (let index = 0; index < inputArray.length; index++) {
        let curInfo = inputArray[index];
        lookupMap[curInfo[keyName]] = curInfo;
    }
    return lookupMap;
}

export const sumValuesInArray = function(inputArray) {
    let totalValue = 0;
    for (let index = 0; index < inputArray.length; index++) {
        totalValue += inputArray[index];
    }
    return totalValue;
}

export const findSingleIndexInArrayObj = function(key, value, arrayObjList) {
    var foundIndex = -1;
    for (var index = 0; index < arrayObjList.length; index++) {
        var curObj = arrayObjList[index];
        var curValue = curObj[key];
        if (curValue == value) {
            foundIndex = index;
            break;
        }
    }
    return foundIndex;
}

export const findSingleIndexInArray1 = function(value, arrayList) {
    var foundIndex = -1;
    for (var index = 0; index < arrayList.length; index++) {
        var curValue = arrayList[index];
        if (curValue == value) {
            foundIndex = index;
            break;
        }
    }
    return foundIndex;
}

/* eslint-disable no-useless-concat */
/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */


// circular -- fix as needed.
let AllImages = require("../../../src/asset/image/AllImages.js");


let AllFonts = {};
AllFonts["circular_std_black_italic"] = "circular_std_black_italic";
AllFonts["circular_std_black"] = "circular_std_black";
AllFonts["circular_std_bold_italic"] = "circular_std_bold_italic";
AllFonts["circular_std_bold"] = "circular_std_bold";
AllFonts["circular_std_book_italic"] = "circular_std_book_italic";
AllFonts["circular_std_book"] = "circular_std_book";
AllFonts["circular_std_medium_italic"] = "circular_std_medium_italic";
AllFonts["circular_std_medium"] = "circular_std_medium";

AllFonts["raleway_bold"] = "raleway_bold";
AllFonts["raleway_extrabold"] = "raleway_extrabold";
AllFonts["raleway_extralight"] = "raleway_extralight";
AllFonts["raleway_heavy"] = "raleway_heavy";
AllFonts["raleway_light"] = "raleway_light";
AllFonts["raleway_medium"] = "raleway_medium";
AllFonts["raleway_regular"] = "raleway_regular";
AllFonts["raleway_semibold"] = "raleway_semibold";
AllFonts["raleway_thin"] = "raleway_thin";

export { AllImages, AllFonts };
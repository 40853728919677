/* eslint-disable no-useless-concat */
//var {Buffer} = require("buffer");
var Crypto = require("crypto-browserify");


var AES_KEY = "3" + "c" + "3" + "5" + "b" + "0" + "d" + "3" + "3" + "8" + 
"e" + "0" + "c" + "f" + "f" + "6" + "8" + "a" + "d" + "f" + "f"+ "e"+ 
"4" + "a"+ "3"+ "d" + "7"+ "1"; // "3c35b0d338e0cff68adffe4a3d71"

var AES_IV = "l" + "i" + "p" + "s" + "u" + "m" + "I" + "p" + "s" + "u" + "m" + 
"7" + "9" + "1" + "9" + "0"; // "lipsumIpsum79190"
 // ^^ 16 characters

export const decryptString = function(decodedDataStr) {
    var aesKey = AES_KEY;
    var aesIV = AES_IV;
    var queryString = aesDecrypWithKeys(aesKey, aesIV, decodedDataStr);
   return queryString;
}

export const encryptString = function(dataStr) {
    var aesKey = AES_KEY;
    var aesIV = AES_IV;
    var codedRespString = aesEncryptWithKeys(aesKey, aesIV, dataStr);
	return codedRespString;
}

function aesDecrypWithKeys(cryptkeyString, ivString, preEncryptedStr) {
    var decodedStr = null;
    try {
        var cryptkey = Crypto.createHash("sha256").update(cryptkeyString).digest();
        var encryptedStr2 = Buffer.from(preEncryptedStr, "base64").toString("binary");
        var decipher = Crypto.createDecipheriv("aes-256-cbc", cryptkey, ivString);

        var tempStr = decipher.update(encryptedStr2, "binary", "utf8");
        tempStr += decipher.final("utf8");
        
        decodedStr = tempStr;
    } catch (err) {
        decodedStr = null;
        console.log("try-catch-error: " + err);
    }
    return decodedStr;
}

function aesEncryptWithKeys(cryptkeyString, ivString, cleardata) {
	var cryptkey = Crypto.createHash("sha256").update(cryptkeyString).digest();

    var encipher = Crypto.createCipheriv("aes-256-cbc", cryptkey, ivString);
    
    var encryptedStr  = encipher.update(cleardata, "utf8", "binary");
    encryptedStr += encipher.final("binary");

    var encodeStr64 = Buffer.from(encryptedStr, "binary").toString("base64");
    return encodeStr64;
}

export const decryptToDataObject = function(rawDataStr) {
    var decodedStr = decodeURIComponent(rawDataStr);
    var descryptedString = decryptString(decodedStr);
    var respData = JSON.parse(descryptedString);
    return respData;
}

import React, { useState, useRef } from 'react';
import { OverlayTrigger, PopoverBody } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Overlay from 'react-bootstrap/Overlay';
import Popover from 'react-bootstrap/Popover';
import ClipboardCopy from './ClipboardCopy';

export default function TooltipEx({ sData , children}) {
  const [show, setShow] = useState(false);
  return (
    <div 
       onMouseEnter={() => setShow(true)} 
    onMouseLeave={() => setShow(false)}
    >
      <OverlayTrigger
        className="bg-white"
        show={show}
        onHide={() => setShow(false)}
        overlay={
          <Popover
            style={{ margin: 0 }}
            id="popover-positioned-bottom"
            className="bg-white w-100"
            placement="bottom-start"
          >
            <PopoverBody className="p-2">
              {children}
            </PopoverBody>
          </Popover>
        }
        placement="bottom"
      >
        <Button className="m-0 p-0 fs--1 text-primary" variant="outline">
          {sData}
        </Button>
      </OverlayTrigger>
    </div>
  );
}

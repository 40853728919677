/* eslint-disable no-useless-concat */
/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */

import React from "react";
import { Text, View, Image, TouchableOpacity } from "react-native";
import * as StyleUtil from "../../karma_lib/util/StyleUtil.js";
import * as AllAssets from "../../karma_lib/asset/AllAssets.js";
import * as LiteElements from "../../karma_lib/component/LiteElements.js";

const { circular_std_medium_italic, circular_std_black, circular_std_book, circular_std_medium } = AllAssets.AllFonts;

const STYLE_CONTENT = StyleUtil.createStyle1({
    backgroundColor: "#FFFFFFFF",
    alignItems: "center",
    flexDirection: "column",
    marginTop: "75px"
});

const ICON_DIM = "120px";
const STYLE_ICON = StyleUtil.createStyle1({
    width: ICON_DIM,
    height: ICON_DIM
});

const STYLE_TITLE = StyleUtil.createStyleText({
    fontFamily: circular_std_medium,
    fontSize: 18,
    color: "#5B6275FF",
    textAlign: "center"
});

const STYLE_MSG = StyleUtil.createStyleText({
    fontFamily: circular_std_book,
    fontSize: 16,
    color: "#7A7A7AFF",
    textAlign: "center",
    paddingLeft: "32px",
    paddingRight: "32px"
});

function EmptyPage(props){
    const topData = props["topData"];
    let screenHeightStr = window.screen.height + "px";

    let retElement = (<View style={STYLE_CONTENT}>
            <Image source={topData["empty_icon"]}  style={STYLE_ICON} />
            {LiteElements.createPaddingVertical("24px")}
            <Text style={STYLE_TITLE}>{topData["empty_title"]}</Text>
            {LiteElements.createPaddingVertical("24px")}
            <Text style={STYLE_MSG}>{topData["empty_msg"]}</Text>
        </View>)
    return retElement;
    //return (<div style={STYLE_BLANK_SCREEN}> </div>)
}

export const createPage = function(iconObj, title, msg) {
    var topData = {};
    topData["empty_icon"] = iconObj;
    topData["empty_title"] = title;
    topData["empty_msg"] = msg;
    return <EmptyPage topData={topData}/>
}

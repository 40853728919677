import * as RequestUtil from "../http_request/RequestUtil.js";
import * as ArrayUtil from  "karma_lib/util/ArrayUtil.js";

export const changeResetPassword = function(hash, password, callback) {
    let params = {};
    params["hash"] = hash;
    params["password"] = password;
    var fullUrl = RequestUtil.createFullUrlSimple("/ua_partner/password_reset_change", params);
    RequestUtil.requestGET(fullUrl,
        function(errorInfo, respData) {
            if (errorInfo) {
                callback(errorInfo);
            } else {
                let responseInfo = {};
                responseInfo["confirmationTitle"] = respData["confirmation_title"];
                responseInfo["confirmationMsg"] = respData["confirmation_msg"];
                callback(null, responseInfo);
            }
    });
}
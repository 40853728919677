export const parseInputArray = function(array) {
    let retArray = [];
    for (let index = 0; index < array.length; index++) {
        let curObj = array[index];
        let newObj = {
            "entry_id": curObj["entry_id"],
            "entry_disp": curObj["entry_disp"]
        };
        retArray.push(newObj);
    }
    return retArray;
}
import React from 'react';
import { Button, Modal } from 'react-bootstrap';

export default function CustomModal({ show, setShow,children,size='lg',title='Modal Heading' }) {
  return (
    <Modal
      show={show}
      size={size}
      onHide={() => setShow(!show)}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title className='text-break'>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{children}</Modal.Body>
    </Modal>
  );
}

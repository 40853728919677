/* eslint-disable no-useless-concat */
/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */

// https://stackoverflow.com/questions/1144783/how-to-replace-all-occurrences-of-a-string
export const replaceAll = function(str, find, replace) {
    return str.replace(new RegExp(escapeRegExp(find), "g"), replace);
}

function escapeRegExp(string) {
    return string.replace(/[.*+\-?^${}()|[\]\\]/g, "\\$&"); // $& means the whole matched string
}

export const trimAndLower1 = function(inputStr) {
    var inputStr1 = inputStr.trim();
    var inputStr12 = inputStr1.toLowerCase();
    return inputStr12;
}

export const safeTrimAndLower = function(inputStr) {
    if (inputStr) {
        var inputStr1 = inputStr.trim();
        var inputStr12 = inputStr1.toLowerCase();
        return inputStr12;
    } else {
        return null;
    }
}

export const safeLower = function(inputStr) {
    if (inputStr) {
        var inputStr2 = inputStr.toLowerCase();
        return inputStr2;
    } else {
        return null;
    }
}

// https://stackoverflow.com/questions/25361896/mongo-insert-error-invaliddocument-key-names-must-not-contain-the-null-byte
export const containsNullByte = function(inputStr) {
    var foundIndex = inputStr.indexOf("\u0000");
    if (foundIndex != -1) {
        return true;
    } else {
        return false;
    }
}

export const removeSpacesFromString = function(string) {
	var newString = string.replace(/\s/g,"");
	return newString;
}

export const findFirstIndex = function(string, searchTerm) {
    var firstIndex = string.indexOf(searchTerm);
    return firstIndex;
}

// https://stackoverflow.com/questions/17010119/decodeuri-decodes-space-as-symbol
export const customDecode = function(str) {
    // Java"s URLEncoder converts spaces to plus sign.
    // Javascript"s encodeURIComponent converts spaces to %20
    var retValue;
    if (str) {
        retValue = decodeURIComponent(str.replace(/\+/g, " "));
    } else {

    }
    return retValue;
};

export const hasSuffixStr = function(value, suffixVal) {
    var retValue;
    if (value) {
        var numSuffixChars = suffixVal.length;
        var resSuffixValue = value.slice(-numSuffixChars);
        if (resSuffixValue == suffixVal) {
            retValue = true;
        } else {
            retValue = false;
        }
    } else {
        retValue = false;
    }
    return retValue;
}

export const checkRemoveSuffix = function(preClientUserId, suffixVal) {
    var retValue;
    if (preClientUserId) {
        if (this.hasSuffixStr(preClientUserId, suffixVal)) {
            var numSuffixChars = suffixVal.length;
            retValue = preClientUserId.substr(0, preClientUserId.length - numSuffixChars);
        } else {
            retValue = preClientUserId;
        }
    }
    return retValue;
}

// Hacky. Use sparingly.
export const removeBadKeys = function(decodedParams) {
    var keysArray = Object.keys(decodedParams);
    for (var index = 0; index < keysArray.length; index++) {
        var curKey = keysArray[index];
        var firstChar = curKey[0];
        if (firstChar == " ") {
            delete decodedParams[curKey];
        }
    }
}

export const formatString = function(inputStr) {
    var retValue;
    if (inputStr) {
        if (inputStr == "null" || inputStr == "undefined") {
            retValue = null;
        } else {
            retValue = inputStr;
        }
    } else {
        retValue = null;
    }
    return retValue;
}

export const removeOuterBraces = function(inputStr) {
    var resultStr = inputStr.replace(/^{(.*)}$/, "$1");
    return resultStr;
}
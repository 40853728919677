import React from 'react';
import { Dropdown } from 'react-bootstrap';

export default function DropdownOptionKeyPair({
  className,
  selected,
  setSelected,
  disabled=false,
  listItems,
  defaultMenuHigth=true,
  keyName={
    key:'key',
    value: 'value'
  }
}) {
  return (
    <Dropdown className={className}>
      <Dropdown.Toggle className="w-100 d-flex justify-content-between align-items-center me-1 bg-white border text-black fw-medium" disabled={disabled}>
        {selected[keyName?.value]}
      </Dropdown.Toggle>
      <Dropdown.Menu className="overflow-scroll w-100" style={{
        height:defaultMenuHigth?'auto':'250px',
      }}>
        {listItems &&
          listItems.map((item, index) => (
            <Dropdown.Item key={index} onClick={() => setSelected(item)}>
              {item[keyName?.value] }
            </Dropdown.Item>
          ))}
      </Dropdown.Menu>
    </Dropdown>
  );
}

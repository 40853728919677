"use strict";

export const jsonParse = function(jsonString) {
	var jsonObj = null;
	try {
		jsonObj = JSON.parse(jsonString)
	} catch (exObj) {
        console.log("error_jsonparse_message "+ exObj["message"] + " input: " + jsonString);
	}
	return jsonObj;
}

export const jsonStringify = function(inputObj) {
    return JSON.stringify(inputObj);
}
import * as RequestUtil from "../http_request/RequestUtil.js";
import * as ArrayUtil from  "karma_lib/util/ArrayUtil.js";
 
export const sendContactUsEmail = function(email, subject, body, callback) {
    let params = {};
    params["email"] = email;
    params["subject"] = subject;
    params["body"] = body;
    var fullUrl = RequestUtil.createFullUrlSimple("/ua_partner/send_contact_us_email", params);
    RequestUtil.requestGET(fullUrl,
        function(errorInfo, respData) {
            if (errorInfo) {
                callback(errorInfo);
            } else {
                let responseInfo = {};
                responseInfo["successMsg"] = respData["success_msg"];
                callback(null, responseInfo);
            }
    });
}
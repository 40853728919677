import React, { createContext, useEffect, useState } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-toastify/dist/ReactToastify.min.css';
import Layout from './layouts/Layout';
import { DataContext } from 'context/Context';

const App = () => {
  const [currentUrl, setCurrentUrl] = useState('/');

  return (
    <Router basename={process.env.PUBLIC_URL}>
      <DataContext.Provider value={{ currentUrl, setCurrentUrl }}>
        <Layout />
      </DataContext.Provider>
    </Router>
  );
};

export default App;

import { faInbox } from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import { Button, Card } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

export default function Confirmation({ title="Title", message="Message" , image="", path="/", buttonText="button", cbClick=null}) {
    const naviate = useNavigate();
  return (
    <div
      className="d-flex justify-content-center align-items-center"
      style={{
        height: '100vh'
      }}
    >
      <Card
        className="p-5"
        style={{
          width: '480px'
        }}
      >
        <div className="mb-3">
          <div>
            <div className='text-center mb-3'><img src={image} width={"80px"} height={"80px"}/></div>
           
          </div>
          <div className ="mt-4">
            <h3 className="text-700 text-center fw-bold fs-2">{title} </h3>
          </div>
          <div className ="mt-3 mb-4">
            <h4 className="text-600 text-center fw-normal fs-0">{message}</h4>
          </div>
        </div>
        {/* add the url */}
        <Button onClick={()=> {
            naviate(path)
            if (cbClick) {
                cbClick();
            }
        }}> {buttonText} </Button>
      </Card>
    </div>
  );
}

import React, { useState } from "react";
import { Button, Card, Form } from "react-bootstrap";
import * as ContractUsEmailRequest from "http_request/ContractUsEmailRequest.js";
import * as MyAlertDialog2 from "karma_lib/dialog/MyAlertDialog2.js";
import * as PageUtil from "util/PageUtil.js";
import { Link, useNavigate } from "react-router-dom";

const TARGET_EMAIL = "adops@appkarma.io";

function checkDisableSubmitButton(subject, message) {
    let isValid = subject && message;
    let disable = isValid ? false : true;
    return disable;
}

let sAlertDialogObject2 = MyAlertDialog2.createDialog();
export default function ContactUs() {
  const [subject, setSubject] = useState("Advertising Dashboard inquiry");
  const [message, setMessage] = useState("");

  const navigate = useNavigate();
  const [modalLoading, setModalLoading] = useState(false);
  const [unused, setForceUpdateObject] = useState({ dummy_key: "dummy_val" });
  function forceRefreshView() {
    setForceUpdateObject({ dummy_key: "dummy_val" });
  }
  return (
    <div
      className="d-flex justify-content-center align-items-center"
      style={{
        height: "100vh"
      }}
    >
      <Card
        className="p-5"
        style={{
          width: "480px"
        }}
      >
        <div className="mb-3">
          <div>
            <h3 className="text-700 fw-bold fs-2 mb-3">Contact Us</h3>
          </div>
          <div>
            <div>
              <div className="my-2">
                <span className="fw-bold">To : </span>
                <span className="text-black">{TARGET_EMAIL}</span>
              </div>
              <div className="d-flex my-auto gap-2">
                <div className="fw-bold pt-1">Subject: </div>
                <Form className="w-100">
                  <Form.Group className="">
                    <Form.Control
                      placeholder={"Subject"}
                      value={subject}
                      name="subject"
                      onChange={e => {
                        setSubject(e.target.value);
                      }}
                      type="text"
                    />
                  </Form.Group>
                </Form>
              </div>
              <div className="d-flex my-auto mt-2 gap-1">
                <div className="fw-bold pt-1">Message: </div>
                <Form className="w-100">
                  <Form.Group className="">
                    <Form.Control
                      placeholder={"Message"}
                      value={message}
                      name="message"
                      onChange={e => {
                        setMessage(e.target.value);
                      }}
                      as="textarea"
                      rows={3}
                    />
                  </Form.Group>
                </Form>
              </div>
            </div>
            <Button className="w-100 mt-4"
              disabled={checkDisableSubmitButton(subject, message)}
              onClick={() => {
                setModalLoading(true);
                ContractUsEmailRequest.sendContactUsEmail(TARGET_EMAIL, subject, message,
                       (errorInfo, responseInfo) => {
                        if (errorInfo) {
                            setModalLoading(false);
                            PageUtil.handleErrorResponse(
                                errorInfo,
                                navigate,
                                forceRefreshView,
                                sAlertDialogObject2
                              );
                        } else {
                            setModalLoading(false);
                            setSubject("");
                            setMessage("");
                            MyAlertDialog2.showDialogSuccess(responseInfo["successMsg"], sAlertDialogObject2,
                            (buttonType) => {
                                forceRefreshView();
                            });
                        }
                    });
              }}
            >Submit</Button>
          </div>
        </div>
      </Card>
      {sAlertDialogObject2["elem_view"]}
      {modalLoading ? PageUtil.createLoadingIndicator() : null}
    </div>
  );
}

import * as RequestUtil from "../http_request/RequestUtil.js";
import * as ArrayUtil from  "karma_lib/util/ArrayUtil.js";

export const sendLogin = function(email, password, callback) {
    let params = {};
    params["email"] = email;
    params["password"] = password;
    var fullUrl = RequestUtil.createFullUrlSimple("/ua_partner/log_in", params);
    RequestUtil.requestGET(fullUrl,
        function(errorInfo, respData) {
            if (errorInfo) {
                callback(errorInfo, null);
            } else {
                let dsbUser = {};
                dsbUser["dfSessionToken"] = respData["user_session_token"];
                dsbUser["sourceDisp"] = respData["user_source_disp"];
                dsbUser["email"] = respData["user_email"];
                dsbUser["isAdmin"] = respData["user_is_admin"];
                dsbUser["sourceChoiceArray"] = respData["user_source_choice_array"];
                let responseInfo = {};
                responseInfo["dashboard_user"] = dsbUser;
                callback(null, responseInfo);
            }
    });
}
import React from 'react';
import { Button, Tab } from 'react-bootstrap';
import IconAlert from 'components/common/IconAlert';
import Modal from 'react-bootstrap/Modal';
import * as MyText from "karma_lib/component/MyText.js";

function MyDialog(props) {
    let topData = props["top_data"];
    let [modalDialogState, setDialogState] = React.useState(initDefaulteStateInfo());
    
    topData["cb_ref_set_dialog_state"](setDialogState);

    let modalMsg = modalDialogState["modal_msg"];
    let cbButtonClick = modalDialogState["cb_button_click"];
    let variant = modalDialogState["variant"];

    let retContent = (
            <Modal
            show={modalDialogState["is_show"]}
            onHide={() => {}}
            size="lg"
            centered
            backdrop="static"
            >
            <IconAlert variant={variant} dismissible onClose={
                () => {
                    let stateInfo = initStateShow(modalDialogState, false);
                    setDialogState(stateInfo);
                    if (cbButtonClick) {
                        cbButtonClick();
                    }
                }
                }>
                {MyText.createText1(modalMsg)}
            </IconAlert>
            </Modal>
    );
    
    return retContent;
}

export const createDialog = function() {
    let dialogObject = {};

    let topData = {};    
    topData["cb_ref_set_dialog_state"] = ((cbSetDialogState) => {
        dialogObject["set_dialog_state"] = cbSetDialogState;
    });

    let elemView = <MyDialog top_data={topData} />
    dialogObject["elem_view"] = elemView;
    return dialogObject;
}

export const showDialogSuccess = function(modalMsg, dialogObject, cbButtonClick) {
    let setDialogState = dialogObject["set_dialog_state"];
    let isShow = true;
    let variant = "success";
    let stateInfo = initStateInfo(isShow, modalMsg, variant, cbButtonClick);
    setDialogState(stateInfo);
}

export const showDialogError = function(modalMsg, dialogObject, cbButtonClick) {
    let setDialogState = dialogObject["set_dialog_state"];
    let isShow = true;
    let variant = "danger";
    let stateInfo = initStateInfo(isShow, modalMsg, variant, cbButtonClick);
    setDialogState(stateInfo);
}

// export const hideDialog = function(dialogObject) {
//     let stateInfo = initDefaulteStateInfo();
//     let setDialogState = dialogObject["set_dialog_state"];
//     setDialogState(stateInfo);
// }

function initDefaulteStateInfo() {
    let isShow = false;
    return initStateInfo(isShow, "", "success", null);
}

function initStateShow(modalDialogState, isShow) {
    let modalMsg = modalDialogState["modal_msg"];
    let variant = modalDialogState["variant"];
    let cbButtonClick = modalDialogState["cb_button_click"];
    return initStateInfo(isShow, modalMsg, variant, cbButtonClick);
}

function initStateInfo(isShow, modalMsg, variant, cbButtonClick) {
    let objInfo = {};
    objInfo["is_show"] = isShow;
    objInfo["modal_msg"] = modalMsg;
    objInfo["variant"] = variant;
    objInfo["cb_button_click"] = cbButtonClick;
    return objInfo;
}
import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Link } from "react-router-dom";
import * as StorageUtil from "karma_lib/util/StorageUtil.js";
import DropdownOptionKeyPair from "util/DropdownOptionKeyPair.js";
import * as PageUtil from "util/PageUtil.js";

const USE_CACHE_SELECTED = true;

let createSourceLabel = (textClass) => {
    let sourceElem;
    let isAdmin = StorageUtil.getIsAdmin();
    let sourceChoiceArray = StorageUtil.getSourceChoiceArray();
    if (isAdmin) {
        let initialEntry;

        if (USE_CACHE_SELECTED) {
            let selectedEntry = StorageUtil.getSelectedUtmSrcEntry();
            if (selectedEntry) {
                initialEntry = selectedEntry;
            } else {
                initialEntry = sourceChoiceArray[0];
            }
        } else {
            initialEntry = sourceChoiceArray[0];
        }

        const [utmSourceEntry, setUtmSourceEntry] = React.useState(initialEntry);
        StorageUtil.setSelectedUtmSourceEntry(utmSourceEntry);
        sourceElem = <div>
            <DropdownOptionKeyPair
            setSelected={item => {
                setUtmSourceEntry(item);
                PageUtil.reloadPage();
            }}
            selected={utmSourceEntry}
            className={""}
            listItems={sourceChoiceArray}
            keyName={{ key: "entry_id", value: "entry_disp" }}
            />
      </div>
    } else {
        sourceElem = <span className={classNames("font-sans-serif text-warning fs-2", textClass)}>{StorageUtil.getUserSourceDisplay()} </span>
    }
    return sourceElem;
}

const Logo = ({ at, width, className, textClass, ...rest }) => {
  return (
    <div
      className={classNames(
        "text-decoration-none",
        { "navbar-brand text-left": at === "navbar-vertical" },
        { "navbar-brand text-left": at === "navbar-top" }
      )}
      {...rest}
    >
      <div
        className={classNames(
          "d-flex",
          {
            "align-items-center py-3": at === "navbar-vertical",
            "align-items-center": at === "navbar-top",
            "flex-center fw-bolder fs-5 mb-4": at === "auth"
          },
          className
        )}
      >
        {/* <img className="me-2" src={logo} alt="Logo" width={width} /> */}
        {createSourceLabel(textClass)}
      </div>
    </div>
  );
};

Logo.propTypes = {
  at: PropTypes.oneOf(["navbar-vertical", "navbar-top", "auth"]),
  width: PropTypes.number,
  className: PropTypes.string,
  textClass: PropTypes.string
};

Logo.defaultProps = { at: "auth", width: 58 };

export default Logo;

/* eslint-disable no-useless-concat */
/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */

var sLocTextMap = createLocMap();

function addText(textId, objInfo, objMap) {
    if (objMap[textId] == null) {
        objMap[textId] = objInfo;
    } else {
        throw new Error("already have textId: " + textId);
    }
}

function createLocMap() {
    var objMap = {};

    addText("button_install", {
        "en": "Install",
        "zh-TW": "安裝",
        "zh-CN": "安装",
        "es": "Instalar ",
        "pt": "Instalar",
        "de": "Installieren",
        "fr": "Installer",
        "it": "Installa",
        "nl": "Installeer",
        "ja": "インストール",
        "ko": "설치"
    }, objMap);


    addText("button_later", {
        "en": "Later",
        "zh-TW": "稍後",
        "zh-CN": "稍后",
        "es": "Luego",
        "pt": "Mais tarde",
        "de": "Später",
        "fr": "Plus tard",
        "it": "Più tardi",
        "nl": "Later",
        "ja": "後で",
        "ko": "나중에"
    }, objMap);

    addText("button_more", {
        "en": "More",
        "zh-TW": "更多",
        "zh-CN": "更多",
        "es": "Más",
        "pt": "Mais",
        "de": "Mehr",
        "fr": "Plus",
        "it": "Altro",
        "nl": "Meer",
        "ja": "もっと",
        "ko": "더보기"
    }, objMap);

    addText("button_next", {
        "en": "Next",
        "zh-TW": "接下來",
        "zh-CN": "接下来",
        "es": "Próximo",
        "pt": "Próximo",
        "de": "Weiter",
        "fr": "Suivant",
        "it": "Successivo",
        "nl": "Volgende",
        "ja": "次に",
        "ko": "다음"
    }, objMap);

    addText("button_no", {
        "en": "No",
        "zh-TW": "序號",
        "zh-CN": "序号",
        "es": "No",
        "pt": "Não",
        "de": "Nein",
        "fr": "Non",
        "it": "No",
        "nl": "Nee",
        "ja": "いいえ",
        "ko": "아니오"
    }, objMap);

    addText("button_ok", {
        "en": "OK",
        "zh-TW": "確認",
        "zh-CN": "确认",
        "es": "OK",
        "pt": "OK",
        "de": "OK",
        "fr": "OK",
        "it": "OK",
        "nl": "OK",
        "ja": "了解",
        "ko": "네"
    }, objMap);
    
    addText("general_delete", {
        "en": "Delete",
        "zh-TW": "刪除",
        "zh-CN": "删除",
        "es": "Borrar",
        "pt": "Delete",
        "de": "Löschen",
        "fr": "Effacer",
        "it": "Elimina",
        "nl": "Delete",
        "ja": "Delete",
        "ko": "Delete"
    }, objMap);

    addText("general_featured", {
        "en": "Featured",
        "zh-TW": "精選",
        "zh-CN": "精选",
        "es": "Destacados",
        "pt": "Destaque",
        "de": "Ausgewählte",
        "fr": "En Exclusivité",
        "it": "In Evidenza",
        "nl": "Aanbevolen",
        "ja": "特集",
        "ko": "추천"
    }, objMap);

    addText("offer_empty_aggwall_title", {
        "en": "No Offers",
        "zh-TW": "無任務",
        "zh-CN": "无任务",
        "es": "No hay ofertas",
        "pt": "Sem Ofertas",
        "de": "Keine Angebote",
        "fr": "Pas d'Offres",
        "it": "Nessuna offerta",
        "nl": "Geen aanbiedingen",
        "ja": "オファーがありません",
        "ko": "제안 없음"
    }, objMap);

    addText("offer_empty_aggwall_msg", {
        "en": "Please try again. If you continue to see no offers returned, please contact %1s.",
        "zh-TW": "請再試一次。如果您仍然未看到任務，請聯繫%1s。",
        "zh-CN": "请再试一次。如果您仍然未看到任务，请联系%1s。",
        "es": "Inténtalo de nuevo. Si continúas viendo que no se devuelven las ofertas, ponte en contacto con %1s.",
        "pt": "Por favor, tente novamente. Se continuar sem visualizar as ofertas, por favor entre em contato com %1s.",
        "de": "Bitte versuchen Sie es erneut. Wenn Sie weiterhin keine Angebote erhalten, wenden Sie sich bitte an %1s.",
        "fr": "Veuillez réessayer. Si vous continuez à ne pas recevoir d'offres, veuillez contacter %1s.",
        "it": "Riprova. Se continuate a non vedere offerte, contatta %1s.",
        "nl": "Probeer het nog eens. Als je nog steeds geen aanbiedingen terug ziet, neem dan contact op met %1s.",
        "ja": "再度お試し下さい。もし続けてオファーがないと表示されたら、%1sにご連絡下さい。",
        "ko": "다시 시도해주세요. 계속해서 반환되는 제안이 없는 경우 %1s에 문의하십시오."
    }, objMap);

    return objMap;
}	


export const getLocText = function(mainKey, preLanguage) {
    var language;
    if (preLanguage) {
        language = preLanguage;
    } else {
        language = "en";
	}
	
    var result;

    var locObj = sLocTextMap[mainKey];
    if (locObj) {
        if (locObj[language]) {
            result = locObj[language];
        } else {
            result = locObj["en"];
        }
    } else {
        result = "missing_text";
    }
    
    return result;
}

export const getStrObj = function() {
	return createLocMap();
}

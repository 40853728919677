import * as LoadingIndicator from "../karma_lib/component/LoadingIndicator.js";
import * as EmptyPage from "../karma_lib/component/EmptyPage.js";
import * as MyConstants from "../core/MyConstants.js";
import * as LocStringUtil from "../karma_lib/util/LocStringUtil.js";
import * as AllAssets from "../karma_lib/asset/AllAssets.js";
import * as ArrayUtil from  "karma_lib/util/ArrayUtil.js";
import * as MyAlertDialog2 from "karma_lib/dialog/MyAlertDialog2.js";
import * as StorageUtil from "karma_lib/util/StorageUtil.js";
import * as TimeUtil from "karma_lib/util/TimeUtil.js";

const { empty_offers} = AllAssets.AllImages;

export const createLoadingIndicator = function() {
    return LoadingIndicator.createLoader();
}

export const createEmptyOffersPage = function() {
    const emptyTitle = LocStringUtil.getLocString0("offer_empty_aggwall_title");
    const emptyMsg = LocStringUtil.getLocString1("offer_empty_aggwall_msg", MyConstants.SUPPORT_EMAIL);
    return EmptyPage.createPage(empty_offers, emptyTitle, emptyMsg);
}

export const initEntry = function(value, displayVal) {
    let objInfo = {};
    objInfo["entry_id"] = value;
    objInfo["entry_value"] = value; // Phase out
    if (displayVal) {
        objInfo["entry_disp"] = displayVal;
        objInfo["entry_display_val"] = displayVal; // Phase out
    } else {
        objInfo["entry_disp"] = value;
        objInfo["entry_display_val"] = value; // Phase out.
    }
    return objInfo;
}

export const findDisplayValue = function(entryId, inputArray) {
    let foundIndex = ArrayUtil.findSingleIndexInArrayObj("entry_id", entryId, inputArray);
    let foundObj = foundIndex >= 0 ? inputArray[foundIndex] : null;
    let retValue = foundObj ? foundObj["entry_disp"] : null;
    return retValue;
}

export const createQueryArrayAll = function(inputArray) {
    let retArray = [];
    for (let index = 0; index < inputArray.length; index++) {
        let curInfo = inputArray[index];
        if (curInfo["entry_id"] != "all") {
            retArray.push(curInfo["entry_id"]);
        }
    }
    return retArray;
}

export const createQueryArrayAll2 = function(inputArray) {
    let retArray = [];
    for (let index = 0; index < inputArray.length; index++) {
        let curValue = inputArray[index];
        if (curValue != "all") {
            retArray.push(curValue);
        }
    }
    return retArray;
}

export const getHackWaitTimeMS = function() {
    return 50;
}

export const createMapValueToDisp = function(inputArray) {
    let lookupMap = {};
    for (let index = 0; index < inputArray.length; index++) {
        let curInfo = inputArray[index];
        lookupMap[curInfo["entry_value"]] = curInfo["entry_display_val"];
    }
    return lookupMap;
}

export const createMapDispToValue = function(inputArray) {
    let lookupMap = {};
    for (let index = 0; index < inputArray.length; index++) {
        let curInfo = inputArray[index];
        lookupMap[curInfo["entry_display_val"]] = curInfo["entry_value"];
    }
    return lookupMap;
}

// https://stackoverflow.com/questions/55546973/react-onmouseenter-event-triggering-on-child-element
export const onClickableMouseEnter = function(event) {
    let targetObj = event["currentTarget"];
    let styleObj = targetObj["style"];
    targetObj["my_prev_fontweight"] = styleObj["fontWeight"];
    styleObj["fontWeight"] = "bold";
    styleObj["cursor"] = "pointer"; // sloppy -- only needs to be set once.
}

export const onClickableMouseLeave = function(event) {
    let targetObj = event["currentTarget"];
    let styleObj = targetObj["style"];
    let prevFontWeight = targetObj["my_prev_fontweight"];
    styleObj["fontWeight"] = prevFontWeight;
}

export const createErrorString = function(errorMsgArray) {
    let errorString = "";
    for (let index = 0; index < errorMsgArray.length; index++) {
      if (index < errorMsgArray.length - 1) {
        errorString += errorMsgArray[index] + "\n";
      } else {
        errorString += errorMsgArray[index];
      }
    }
    return errorString;
}

const APPLICATION_CHOICE_ARRAY = [
    initEntry("appkarma", "appKarma"),
    initEntry("cashkarma", "cashKarma")
];

export const getApplicationChoiceArray1 = function() {
    return APPLICATION_CHOICE_ARRAY;
}

export const getApplicationChoiceArrayWithAll = function() {
    let newArray = ArrayUtil.concatArray(APPLICATION_CHOICE_ARRAY, 
        [ initEntry("all", "ALL")]);
    return newArray;
}

const PLATFORM_CHOICE_ARRAY = [
    initEntry("android"),
    initEntry("ios")
];

export const getPlatformChoiceArray1 = function() {
    return PLATFORM_CHOICE_ARRAY;
}

export const getPlatformChoiceArray2 = function() {
    let newArray = ArrayUtil.concatArray(PLATFORM_CHOICE_ARRAY, 
        [ initEntry("all", "ALL")]);
    return newArray;
}

export const getPlaceholderChoiceArray = function() {
    return [initEntry("placeholder", "---")];
}

const USER_FIELD_TYPE_CHOICE_ARRAY = [
    initEntry("type_user_id", "UserID"),
    initEntry("type_username", "Username"),
    initEntry("type_email", "Email"),
    initEntry("type_client_user_id", "ClientID")
]

export const getUserFieldChoiceArray1 = function() {
    return USER_FIELD_TYPE_CHOICE_ARRAY;
}

const STATUS_CHOICE_ARRAY = [
    initEntry("status_verify", "Verify"),
    initEntry("status_suspend", "Suspend"),
    initEntry("status_deactivate", "Delete"),
    initEntry("status_reactivate_account", "Reactivate Account")
];

export const getStatusChoiceArray = function() {
    return STATUS_CHOICE_ARRAY;
}

const SUSPEND_REASON_ARRAY = [
    "Attribution fraud",
    "Suspicious activity",
    "VPN use",
    "Invite Fraud",
    "Other"
];

export const getSuspendReasonArray = function() {
    return SUSPEND_REASON_ARRAY;
}

const UPDATE_TYPE_ARRAY = [
    initEntry("add_bonus", "Add Bonus"),
    initEntry("update_status", "Update Status"),
    initEntry("set_admin_note", "Add Account Note"),
    initEntry("set_vip", "Set VIP"),
    initEntry("ban_inviter", "Ban Invite Privilege")
];

export const getUpdateTypeArray = function() {
    return UPDATE_TYPE_ARRAY;
}

const BONUS_REASON_ARRAY = [
    "Missing Credit Bonus",
    "Quiz Bonus",
    "IAP Bonus",
    "VIP Promo Bonus",
    "Refund",
    "Other"
];

export const getBonusReasonArray = function() {
    return BONUS_REASON_ARRAY;
}

export const handleErrorResponse = function(errorInfo, navigate, forceRefreshView, sAlertDialogObject2, callback) {
    if (errorInfo["isRedirectToLogin"]) {
        StorageUtil.handleSaveLoginCache();
        navigate("/login")
    } else {
        MyAlertDialog2.showDialogError(
            errorInfo["errorMsg"],
            sAlertDialogObject2,
            (() => {
                if (callback) {
                    callback();
                }
            })
          );
        forceRefreshView();
    }
}

export const reloadPage = function() {
    window.location.reload();
}

export const createDateRangeTitle = function(startDateStr, endDateStr) {
    let retTitle;
    if (startDateStr && endDateStr) {
        let startFormatted = TimeUtil.convertDateToMMDDYYYY(new Date(startDateStr), "/");
        let endFormatted = TimeUtil.convertDateToMMDDYYYY(new Date(endDateStr), "/");
        retTitle = "(" + startFormatted + " - " + endFormatted + ")";
    } else {
        retTitle = "";
    }
    return retTitle;
}

export const createDefaultStartDate = function(curDate) {
    return TimeUtil.resetToFixedTimeOfDay(TimeUtil.dateOffsetX_Days(-30, curDate));
}

export const createDefaultEndDate = function(curDate) {
    return TimeUtil.resetToFixedTimeOfDay(curDate);
}

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Alert, CloseButton } from 'react-bootstrap';
import classNames from 'classnames';
import PropTypes from 'prop-types';

const IconAlert = ({
  variant,
  dismissible,
  children,
  className,
  onClose,
  ...rest
}) => {

    let styleObj;
    let inputClassName;
    let USE_CLASS = false; // Eric comment: Either way works to remove unwanted padding at bottom.

    if (USE_CLASS) {
        // https://stackoverflow.com/questions/45890179/bootstrap-4-alert-has-unwanted-bottom-padding-when-not-in-container
        inputClassName = 'd-flex align-items-center mb-0';
        styleObj = {};
    } else {
        inputClassName = "d-flex align-items-center";
        styleObj = {
            //backgroundColor: "#FF0000",
            marginBottom: "0px"
        }
    }

  const icon = {
    success: 'check-circle',
    info: 'info-circle',
    warning: 'exclamation-circle',
    danger: 'times-circle'
  };
  return (
    <Alert
        style={styleObj}
        variant={variant}
        className={classNames(className, inputClassName)}
        {...rest}
    >
      <div className={`bg-${variant} me-3 icon-item`}>
        <FontAwesomeIcon icon={icon[variant]} className="text-white fs-3" />
      </div>
      <div className="flex-1">{children}</div>
      {dismissible && <CloseButton onClick={onClose} />}
    </Alert>
  );
};

IconAlert.propTypes = {
  variant: PropTypes.oneOf(['success', 'danger', 'warning', 'info']).isRequired,
  dismissible: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
  onClose: PropTypes.func
};

export default IconAlert;

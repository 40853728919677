
const campaignRoutes = {
    icon: 'calendar-alt',
    label: "Campaign",
    to: "/campaign_report"
};

const publisherRoutes = {
    icon: 'calendar-alt',
    label: "Publisher",
    to: "/publisher_report"
};

const dailyRoutes = {
    label: "Daily",
    icon: 'calendar-alt',
    to: "/daily_report"
};

const conversionRoutes = {
    label: "Conversions",
    icon: 'calendar-alt',
    to: "/conversion_report",
};

const ROUTE_CHILDREN_ARRAY = [
    campaignRoutes,
    publisherRoutes,
    dailyRoutes,
    conversionRoutes
];

export const getNavTop = function() {
    return ROUTE_CHILDREN_ARRAY;
}

function initEntry(routeObj, iconName, navId) {
    let newRouteObj = Object.assign({}, routeObj);
    newRouteObj["name"] = routeObj["label"];
    newRouteObj["icon"] = iconName;
    newRouteObj["my_nav_id"] = navId; // Remove?
    newRouteObj["active"] = true;
    return newRouteObj;
}

const dashboardRoutes = {
    label: "Dashboard",
    children: [
        initEntry(campaignRoutes, "chart-pie", "nav_campaign"),
        initEntry(publisherRoutes, "chart-pie", "nav_publisher"),
        initEntry(dailyRoutes, 'calendar-day', "nav_daily"),
        initEntry(conversionRoutes, "user", "nav_conversion")
    ]
};

export const getNavVertical = function(ms) {
    return [dashboardRoutes];
}

// isoDateThresholdStr - String. Looks like:
// "2017-02-15T10:30:59.578Z";
export const timeIsOutdated_Hours = function(deltaHours, lastRefreshDate, curDate) {
    let deltaMillis = deltaHours * 3600 * 1000;
    return timeIsOutdated_Millis(deltaMillis, lastRefreshDate, curDate);
} 

export const timeIsOutdated_Minutes = function(deltaMinutes, lastRefreshDate, curDate) {
    let deltaMillis = deltaMinutes * 60 * 1000;
    return timeIsOutdated_Millis(deltaMillis, lastRefreshDate, curDate);
} 

export const timeIsOutdated_Seconds = function(deltaSeconds, lastRefreshDate, curDate) {
    let deltaMillis = deltaSeconds * 1000;
    return timeIsOutdated_Millis(deltaMillis, lastRefreshDate, curDate);
} 

export const dateOffsetX_Minutes = function(numMinutes, curDate) {
    let milliseconds = numMinutes * 60000;
    let adjustedDate = new Date(curDate.getTime() + milliseconds);
    return adjustedDate;
}

export const dateOffsetX_Hours = function(numHours, curDate) {
    let numMinutes = numHours * 60;
    let adjustedDate = dateOffsetX_Minutes(numMinutes, curDate);
    return adjustedDate;
}

export const dateOffsetX_Days = function(numDays, curDate) {
    let milliseconds = numDays * 3600 * 24 * 1000;
    let adjustedDate = new Date(curDate.getTime() + milliseconds);
    return adjustedDate;
}

export const dateOffsetX_Months = function(numMonths, curDate) {
    let newMonthVal = curDate.getMonth() + numMonths;
    let adjustedDate = new Date(curDate);
    adjustedDate.setMonth(newMonthVal);
    return adjustedDate;
}

// https://stackoverflow.com/questions/33070428/add-year-to-todays-date/33070481
export const dateOffsetX_Years = function(numYears, curDate) {
    let newYearsVal = curDate.getFullYear() + numYears;
    let adjustedDate = new Date(curDate);
    adjustedDate.setFullYear(newYearsVal);
    return adjustedDate;
}

// https://stackoverflow.com/questions/13903897/javascript-return-number-of-days-hours-minutes-seconds-between-two-dates
function timeIsOutdated_Millis(deltaMillis, lastRefreshDate, curDate) {
    let bOutdated;
    if (lastRefreshDate == null) {
        bOutdated = true;
    } else {
        let timeMillis2 = curDate.getTime();
        let timeMillis1 = lastRefreshDate.getTime();
        
        let timeElapsed = timeMillis2 - timeMillis1;
        if (timeElapsed > deltaMillis) {
            bOutdated = true;
        } else {
            bOutdated = false;
        }
    }
    return bOutdated;
}

function leftZeroPad(numVal) {
    let numberStr = String(numVal);
    return numberStr.padStart(2, "0");
}

export const convertDateToYYYYMMDD = function(dateObj) {
	let month = dateObj.getUTCMonth() + 1; //months from 1-12
	let day = dateObj.getUTCDate();
	let year = dateObj.getUTCFullYear();
    
    let monthStr = leftZeroPad(month);
    let dayStr = leftZeroPad(day);
    let yearStr = leftZeroPad(year);
    let resStr = yearStr + "-" + monthStr + "-" + dayStr;
	return resStr;
}

export const convertDateToMMDDYYYY = function(dateObj, separator) {
	var month = dateObj.getUTCMonth() + 1; //months from 1-12
	var day = dateObj.getUTCDate();
	var year = dateObj.getUTCFullYear();
    var monthStr = leftZeroPad(month);
    var dayStr = leftZeroPad(day);
    var yearStr = leftZeroPad(year);
    var convertedDateStr = monthStr + separator + dayStr + separator + yearStr ;
    return convertedDateStr;
}

export const resetToFixedTimeOfDay = function(preDate) {
    let retDate = new Date(preDate);
    retDate.setUTCHours(13); // Some value in middle of day.
                                // Setting to 0 (midnight)
                                // would result in DatePicker displaying the previous day 
                                // (assuming DatePicker converts to a time behind UTC like PST.)
    retDate.setUTCMinutes(0);
    retDate.setUTCSeconds(0);
    retDate.setUTCMilliseconds(0);
    return retDate;
}
/* eslint-disable no-unused-vars */

export const appkarma_icon = require("./appkarma_icon.png");
export const empty_offers = require("./empty_offers.png");
export const icon_check_filled = require("./icon_check_filled.png");
export const icon_check_unfilled = require("./icon_check_unfilled.png");
export const icon_karmapoints = require("./icon_karmapoints.png");
export const icon_warning = require("./icon_warning.png");
export const icon_x_button = require("./icon_x_button.png");
export const scratcher_item_bull = require("./scratcher_item_bull.png");
export const scratcher_item_dog = require("./scratcher_item_dog.png");
export const scratcher_item_dragon = require("./scratcher_item_dragon.png");
export const scratcher_item_jaguar = require("./scratcher_item_jaguar.png");
export const scratcher_item_swan = require("./scratcher_item_swan.png");
import * as RequestUtil from "../http_request/RequestUtil.js";
import * as ArrayUtil from  "karma_lib/util/ArrayUtil.js";

export const changePassword = function(password, callback) {
    let params = {};
    params["password"] = password;
    var fullUrl = RequestUtil.createFullUrlSimple("/ua_partner/password_change", params);
    RequestUtil.requestGET(fullUrl,
        function(errorInfo, respData) {
            if (errorInfo) {
                callback(errorInfo);
            } else {
                let responseInfo = {};
                responseInfo["successMsg"] = respData["success_msg"];
                callback(null, responseInfo);
            }
    });
}
/* eslint-disable no-useless-concat */
/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */

import React from "react"
import axios from "axios"

import * as CryptUtil from "../util/CryptUtil.js";
import * as StorageUtil from "../karma_lib/util/StorageUtil.js";
import * as StringUtil from "../util/StringUtil.js";
import * as ConfigUtil from "../util/ConfigUtil.js";


export const STATE_INPROGRESS = "state_request_inprogress";
export const STATE_SUCCESS = "state_request_success";
export const STATE_ERROR = "state_request_error";

const APPLICATION_NAME  = "ua_partner_dashboard";

const MIN_WAIT_MS = 500;
const TIMEOUT_MS = 30000;
// https://colinricardo.substack.com/p/named-export-vs-default-export?s=r
export const requestGET = function(fullUrl, callback) {
    let sessionToken = StorageUtil.getDfSessionToken();
    let curDate = new Date();
    axios({
        method: "get",
        url: fullUrl,
        headers: {
            "df-session-token": sessionToken
        },
        transformResponse: function(res) {
            return res;
        },
        timeout: TIMEOUT_MS
        })
        .then(response => {
                let statusCode = response["status"];
                let rawDataStr = response["data"];
                //let parsedData = decryptToDataObject(rawDataStr);
                let parsedData = JSON.parse(rawDataStr);
                if (parsedData) {
                    let respData = parsedData;
                    
                    let millisEllapsed = new Date() - curDate;
                    let remainingMillis = MIN_WAIT_MS - millisEllapsed;
                    setTimeout(function() {
                        if (respData["error_type"] && respData["error_msg"]) {
                            let errorInfo = {};
                            if (respData["error_type"] == "error_login_invalid") {
                                errorInfo["isRedirectToLogin"] = true;
                            }
                            errorInfo["errorMsg"] = respData["error_msg"];
                            errorInfo["statusCode"] = response["status"];
                            callback(errorInfo, null);
                        } else {
                            callback(null, respData);
                        }
                    }, remainingMillis);
                } else {
                    throw new Error("error_parse_failed");
                }
            })
            .catch((error) => {
                let statusCode;
                let errorMsg;
                if (error["response"]) {
                    let response = error["response"];
                    let rawDataStr = response["data"];
                    if (rawDataStr) {
                        try {
                            let parsedData = JSON.parse(rawDataStr);
                            statusCode = response["status"];
                            errorMsg = parsedData["error_msg_1"];
                        } catch (exObj) {
                            // rawDataStr is probably xml tags.
                            statusCode = response["status"];
                            errorMsg = rawDataStr;
                        }
                    } else {
                        statusCode = response["status"];
                        errorMsg = error["message"];
                    }
                } else if (error["code"] == "ECONNABORTED") {
                    statusCode = -2;
                    errorMsg = error["message"];
                } else if (error["stack"]) {
                    // Exception thrown.
                    statusCode = -3;
                    let origMsg = error["message"];
                    if (origMsg == "error_parse_failed") {
                        errorMsg = "Something went wrong (C-1008)";
                        errorMsg += " " + findSmallUrl(fullUrl);
                    } else {
                        errorMsg = origMsg;
                    }
                } else {
                    statusCode = -4;
                    errorMsg = "Something went wrong (C-1009)";
                }
                
                let errorInfo = {};
                errorInfo["errorMsg"] = errorMsg;
                errorInfo["statusCode"] = statusCode;

                let millisEllapsed = new Date() - curDate;
                let remainingMillis = MIN_WAIT_MS - millisEllapsed;
                setTimeout(function() {
                    callback(errorInfo, null);
                }, remainingMillis);
            })
}

function findSmallUrl(fullUrl) {
    var retValue;
    try {
        var tempUrl = StringUtil.replaceAll(fullUrl, ConfigUtil.getBaseURL(), "");
        var separator1 = "?" + "data_0";
        var info1 = tempUrl.split(separator1);
        retValue = info1[0];
    } catch (error) {
        retValue = "";
    }
    return retValue;
}

export const initInprogressRequestInfo = function() {
    let info = {
        "requestState": "state_req_inprogress",
        "respData": null,
        "errorMsg": null,
        "statusCode": -1
    };
    return info;
}

export const isRequestInProgress = function(requestState) {
    var retValue;
    if (requestState == "state_req_inprogress") {
        retValue = true;
    } else {
        retValue = false;
    }
    return retValue;
}

export const isRequestResponseError = function(requestState) {
    var retValue;
    if (requestState == "state_req_error") {
        retValue = true;
    } else {
        retValue = false;
    }
    return retValue;
}

/*
export const initCommonDecodedParams = function() {
    var clientVersion = ConfigUtil.getClientVersion();
    var decodedParams = StorageUtil.getNativeDecodedParams();
    decodedParams["webapp_version"] = clientVersion;
    decodedParams["is_webapp"] = "true";
    decodedParams["webapp_type"] = "type_react";
    return decodedParams;
}
*/

function createData0() {
    var clientVersion = ConfigUtil.getClientVersion();
    return APPLICATION_NAME + "_unused_" + clientVersion;
}

export const createFullUrlSimple = function(suffixPath, preParams) {
    if (preParams["dashboard_application_name"]) {
        throw new Error("unexpected_value");
    } else {
        preParams["dashboard_application_name"] = APPLICATION_NAME;
    }

    var prefixUrl = ConfigUtil.getBaseURL() + suffixPath;
    let postParams = {};
    let keys = Object.keys(preParams);
    for (let index = 0; index < keys.length; index++) {
        let curKey = keys[index];
        let value = preParams[curKey];
        postParams[curKey] = encodeURIComponent(value);
    }

    let dataZeroStr = createData0();
    let dataStr1 = createParamStr(postParams);
    var fullUrl = prefixUrl + "?" + "data_0=" + dataZeroStr + "&" + dataStr1;
    return fullUrl;
}

export const createFullUrl = function(suffixPath, params) {
    var prefixUrl = ConfigUtil.getBaseURL() + suffixPath;

    var objMap = {};
    objMap["data"] = encryptToDataString(params);
    objMap["data_0"] = createData0();
    
    var fullUrl = prefixUrl + "?" + "data_0=" + objMap["data_0"] + "&data=" + objMap["data"];
    return fullUrl;
}

function createParamStr(paramsObj) {
    var queryString = "";
    var keys = Object.keys(paramsObj);
    for (var index = 0; index < keys.length; index++) {
        var curKey = keys[index];
        var curValue = paramsObj[curKey];
        if (index == 0) {
            queryString += curKey + "=" + curValue;
        } else {
            queryString += "&" + curKey + "=" + curValue;
        }
    }
    return queryString;
}

function decryptToDataObject(rawDataStr) {
    var decodedStr = decodeURIComponent(rawDataStr);
    var descryptedString = CryptUtil.decryptString(decodedStr);
    var respData = JSON.parse(descryptedString);
    return respData;
}

function encryptToDataString(dataObj) {
    var dataStr1 = createParamStr(dataObj);
    var dataStr2 = CryptUtil.encryptString(dataStr1);
    var dataStr3 = encodeURIComponent(dataStr2);
    return dataStr3;
}

 // http://stackoverflow.com/questions/171251/how-can-i-merge-properties-of-two-javascript-objects-dynamically
 function combineProps(props1, props2) {
    var props3 = {};
    insertIntoProps(props1, props3);
    insertIntoProps(props2, props3);
    return props3;
}

function insertIntoProps(propsInput, propsAll) {
    for (var attrname in propsInput) { 
        //if (propsInput.hasOwnProperty(attrname)) { // extra precaution. 
        // https://stackoverflow.com/questions/39282873/object-hasownproperty-yields-the-eslint-no-prototype-builtins-error-how-to
        if (Object.prototype.hasOwnProperty.call(propsInput, attrname)) {
            propsAll[attrname] = propsInput[attrname];
        } else {
        }
    }
}

import * as RequestUtil from "../http_request/RequestUtil.js";
import * as ArrayUtil from  "karma_lib/util/ArrayUtil.js";

export const notifyLogout = function(callback) {
    let params = {};
    var fullUrl = RequestUtil.createFullUrlSimple("/ua_partner/log_out", params);
    RequestUtil.requestGET(fullUrl,
        function(errorInfo, respData) {
            if (callback) {
                if (errorInfo) {
                    callback(errorInfo, null);
                } else {
                    let responseInfo = {};
                    callback(null, responseInfo);
                }
            }
    });
}
/* eslint-disable no-useless-concat */
/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */

import React from "react";
import * as StyleUtil from "../../karma_lib/util/StyleUtil.js";
 
// https://stackoverflow.com/questions/45424608/what-is-different-between-curly-brace-import-and-import-from-sub-directory-in-es

const STYLE_OUTER = StyleUtil.createStyle1({
    "top": "0px",
    "left": "0px", 
    "position":"fixed", 
    "height":"100%", 
    "width": "100%",
    "backgroundColor": "#00000033",
    "zIndex": 1000000
});

let SIDE_DIM_NUM = 100;

// Either marginLeft / marginTop  or translate would work.
const DIM_OFFSET_PX = (-SIDE_DIM_NUM / 2) + "px";
const SIDE_DIM_PX = SIDE_DIM_NUM + "px";
const DIM_OFFSET_PERCENT = "-50%";

const STYLE_ACTIVITY_INDICATOR_WRAPPER = StyleUtil.createStyle1({
    "top": "30%", 
    "left": "50%", 
    "marginLeft": DIM_OFFSET_PX,
    "marginTop": DIM_OFFSET_PX,
    //"transform": `translate(${DIM_OFFSET_PERCENT}, ${DIM_OFFSET_PERCENT})`,
    "position":"fixed", 
    "width": SIDE_DIM_PX,
    "height": SIDE_DIM_PX,
    "borderRadius": "10px",
    "backgroundColor":"white",
    
    "display": "flex", // Thse 3
    "alignItems": "center", // fields are used to 
    "justifyContent": "center", // center children
                                // https://stackoverflow.com/questions/15328416/position-by-center-point-rather-than-top-left-point
                                // Kay Angevare
});

const INDICATOR_COLOR = "#888888FF";

const STYLE_ACTIVITY_INDICATOR_1 = StyleUtil.createStyle1({
    "color": INDICATOR_COLOR
});

// https://stackoverflow.com/questions/32634715/using-css-transform-translate-with-reactjs
export const createLoader = function() {
    let spinnerView = <div style={STYLE_OUTER}>  
                        <div 
                        style={STYLE_ACTIVITY_INDICATOR_WRAPPER}>
                        <div className="spinner-border"
                            style={STYLE_ACTIVITY_INDICATOR_1}></div>
                        </div>
                        </div>
    return spinnerView;
}

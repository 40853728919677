import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Card, Form } from 'react-bootstrap';
import * as PasswordResetEmailRequest from 'http_request/PasswordResetEmailRequest.js';
import * as MyAlertDialog2 from 'karma_lib/dialog/MyAlertDialog2.js';
import * as PageUtil from 'util/PageUtil.js';
import * as StorageUtil from 'karma_lib/util/StorageUtil.js';
import CloseButton from 'react-bootstrap/CloseButton';
let sAlertDialogObject2 = MyAlertDialog2.createDialog();
const ForgetPasswordForm = () => {
  // State
  const [email, setEmail] = useState('');
  const navigate = useNavigate();
  const [modalLoading, setModalLoading] = useState(false);
  const [unused, setForceUpdateObject] = useState({ dummy_key: 'dummy_val' });

  
  function forceRefreshView() {
    setForceUpdateObject({ dummy_key: 'dummy_val' });
  }
  // Handler
  const handleSubmit = e => {
    e.preventDefault();
    setModalLoading(true);
    PasswordResetEmailRequest.sendPasswordResetEmail(
      email,
      (errorInfo, responseInfo) => {
        if (errorInfo) {
          setModalLoading(false);
          MyAlertDialog2.showDialogError(
            errorInfo['errorMsg'],
            sAlertDialogObject2,
            null
          );
          forceRefreshView();
        } else {
          setModalLoading(false);
          StorageUtil.setConfirmationTexts(
            responseInfo['confirmationTitle'],
            responseInfo['confirmationMsg']
          );
          navigate('/email-confirmation-message');
        }
      }
    );
  };

  return (
    <div
      className="d-flex justify-content-center align-items-center"
      style={{
        height: '100vh'
      }}
    >
      <Card
        className="p-5"
        style={{
          width: '480px'
        }}
      >
        <div className="d-flex align-items-center justify-content-between mb-3">
          <h4 className="text-700 fw-bold fs-2 m-0">Forgot Password?</h4>
          <CloseButton
            className="p-2 border-primary"
            onClick={() => {
              navigate('/login');
            }}
          />
        </div>
        <div className="d-flex align-items-center mb-3">
          <h4 className="text-600 fw-normal fs-0 m-0">
            Enter your email. We'll send you a link to reset your password
          </h4>
        </div>
        <Form className="mt-3" onSubmit={handleSubmit}>
          <Form.Group className="mb-3">
            <Form.Control
              placeholder={'Email address'}
              value={email}
              name="email"
              onChange={({ target }) => setEmail(target.value)}
              type="email"
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Button className="w-100" type="submit" disabled={!email}>
              Send Password Reset Link
            </Button>
          </Form.Group>

          {/* <Link className="fs--1 text-600" to="#!">
            I can't recover my account using this page
            <span className="d-inline-block ms-1"> &rarr;</span>
          </Link> */}
        </Form>
      </Card>
      {sAlertDialogObject2['elem_view']}
      {modalLoading ? PageUtil.createLoadingIndicator() : null}
    </div>
  );
};

ForgetPasswordForm.propTypes = {
  layout: PropTypes.string
};

ForgetPasswordForm.defaultProps = { layout: 'simple' };

export default ForgetPasswordForm;

import * as RequestUtil from "../http_request/RequestUtil.js";
import * as ArrayUtil from  "karma_lib/util/ArrayUtil.js";

export const sendPasswordResetEmail = function(email, callback) {
    let params = {};
    params["email"] = email;
    var fullUrl = RequestUtil.createFullUrlSimple("/ua_partner/send_password_reset_email", params);
    RequestUtil.requestGET(fullUrl,
        function(errorInfo, respData) {
            if (errorInfo) {
                callback(errorInfo);
            } else {
                let responseInfo = {};
                responseInfo["confirmationTitle"] = respData["confirmation_title"];
                responseInfo["confirmationMsg"] = respData["confirmation_msg"];
                callback(null, responseInfo);
            }
    });
}
import React from 'react';
import { Dropdown } from 'react-bootstrap';

export default function DropdownOption({
  className,
  selected,
  setSelected,
  disabled=false,
  listItems,
  objectKey,
  defaultMenuHigth=true,
}) {
  return (
    <Dropdown className={className} disabled={disabled}>
      <Dropdown.Toggle className="w-100 d-flex justify-content-between align-items-center me-1 bg-white border-black text-black fw-normal overflow-scroll" disabled={disabled}>
        {selected}
      </Dropdown.Toggle>
      <Dropdown.Menu className="overflow-scroll w-100" style={{
        height:defaultMenuHigth?'auto':'250px',
      }}>
        {listItems &&
          listItems.map((item, index) => (
            <Dropdown.Item key={index} onClick={() => setSelected(item)}>
              {objectKey ? item[objectKey]: item }
            </Dropdown.Item>
          ))}
      </Dropdown.Menu>
    </Dropdown>
  );
}

import React, { useContext } from 'react';
import NavbarDropdown from './NavbarDropdown';
import * as Routes from 'routes/routes.js';
import { Dropdown, Nav } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import { flatRoutes } from 'helpers/utils';
import * as NavbarUtil from './NavbarUtil.js';
import NavbarDropdownPages from './NavbarDropdownPages';
import NavbarDropdownModules from './NavbarDropdownModules';
import AppContext from 'context/Context';
import { useNavigate } from 'react-router-dom';

function createDropDown(index, children, label, cbOnClick) {
  return (
    <NavbarDropdown key={index} title={label}>
      {flatRoutes(children).map(route => {
        return (
          <Dropdown.Item
            key={route.name}
            as={Link}
            className={route.active ? 'link-600' : 'text-500'}
            to={route.to}
            onClick={() => {
              cbOnClick();
            }}
          >
            {route.name}
          </Dropdown.Item>
        );
      })}
    </NavbarDropdown>
  );
}

function createSingle(index, route, activePathName, cbOnClick) {
  // console.log("routes : ",route)
  return (
    <Nav.Link key={index} onClick={cbOnClick} className='my-auto'>
      {activePathName === route.to ? (
        <span className={'text-100 text-smallcaps fw-bold fs-3 pb-2'}>{route.label}</span>
      ) : (
        <span className='text-500'>{route.label}</span>
      )}
    </Nav.Link>
  );
}

const NavbarTopDropDownMenus = () => {
  const {
    config: { navbarCollapsed, showBurgerMenu },
    setConfig
  } = useContext(AppContext);

  const navigate = useNavigate();
  const activePathName = useLocation().pathname;
  return (
    <>
      {Routes.getNavTop().map((route1, index) => {
        if (route1.children) {
          return createDropDown(index, route1.children, route1['label'], () => {
            if (navbarCollapsed) {
              setConfig('navbarCollapsed', !navbarCollapsed);
            }
            if (showBurgerMenu) {
              setConfig('showBurgerMenu', !showBurgerMenu);
            }
          });
        } else {
          return createSingle(index, route1, activePathName, () => {
            navigate(route1.to);
          });
        }
      })}
    </>
  );
};

export default NavbarTopDropDownMenus;

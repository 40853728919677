import React from 'react';
import { Dropdown } from 'react-bootstrap';

export default function InputDropdownOption({
  className='',
  selected,
  setSelected,
  disabled=false,
  listItems,
  objectKey=''
}) {
  return (
    <Dropdown className={className}>
      <Dropdown.Toggle hover={"true"} className="w-100 d-flex justify-content-between align-items-center me-1 bg-white border-black rounded-end-0 text-black fw-normal overflow-scroll" disabled={disabled}>
        {selected}
      </Dropdown.Toggle>
      <Dropdown.Menu className="w-100">
        {listItems &&
          listItems.map((item, index) => (
            <Dropdown.Item key={index} onClick={() => setSelected(item)}>
              {objectKey ? item[objectKey]: item }
            </Dropdown.Item>
          ))}
      </Dropdown.Menu>
    </Dropdown>
  );
}

import React, { useContext, useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import is from 'is_js';
import MainLayout from './MainLayout';
import SettingsToggle from 'components/settings-panel/SettingsToggle';
import SettingsPanel from 'components/settings-panel/SettingsPanel';
import ErrorLayout from './ErrorLayout';
import { toast, ToastContainer } from 'react-toastify';
import { CloseButton } from 'components/common/Toast';
import Starter from 'components/pages/Starter';
import AppContext from 'context/Context';
import Error404 from 'components/errors/Error404';
import CampaignPage from 'view_page/CampaignPage.js';
import PublisherPage from 'view_page/PublisherPage.js';
import DailyPage from 'view_page/DailyPage.js';
import ConversionPage from 'view_page/ConversionPage.js';
import PasswordChangePage from "view_page/PasswordChangePage.js";
import * as StorageUtil from 'karma_lib/util/StorageUtil.js';
import { useNavigate } from 'react-router-dom';
import * as UrlUtil from 'util/UrlUtil.js';
import LoginForm from 'components/auth/LoginForm';
import ForgetPasswordForm from 'components/auth/ForgetPasswordForm';
import PasswordResetForm from 'components/auth/PasswordResetForm';
import ContactUs from 'components/auth/ContactUs';
import NormalLayout from './NormalLayout';
import Confirmation from 'components/common/Confirmation';
import { BsFacebook } from 'react-icons/bs';
import mailSend from '../asset/image/email-send.png';
import photo from '../asset/image/photo.png';
import success from '../asset/image/success.png';

function rootPage(navigate) {
  useEffect(() => {
    let locPath = UrlUtil.getLocationPath();
    if (locPath == "/") {
      // ^^ Remove this if we can get exact path to work.
      let sessionToken = StorageUtil.getDfSessionToken();
      if (sessionToken) {
        StorageUtil.removeSelectedUtmSrcEntry();
        navigate("/campaign_report");
      } else {
        navigate("/login");
      }
    }
  }, []);
  return <div></div>;
}

const Layout = () => {
  const HTMLClassList = document.getElementsByTagName('html')[0].classList;
  useContext(AppContext);

  useEffect(() => {
    if (is.windows()) {
      HTMLClassList.add('windows');
    }
    if (is.chrome()) {
      HTMLClassList.add('chrome');
    }
    if (is.firefox()) {
      HTMLClassList.add('firefox');
    }
  }, [HTMLClassList]);
  

  const navigate = useNavigate();
  
  let textObjj = StorageUtil.getConfirmationTexts();
  let confirmTitle = textObjj["title"];
  let confirmMsg = textObjj["msg"];
  return (
    <>
      <Routes>
        <Route path="/" element={rootPage(navigate)} />
        <Route element={<ErrorLayout />}>
          <Route path="errors/404" element={<Error404 />} />
        </Route>
        <Route element={<MainLayout />}>
          <Route path="/campaign_report" element={<CampaignPage />} />
          <Route path="/publisher_report" element={<PublisherPage />} />
          <Route path="/daily_report" element={<DailyPage />} />
          <Route path="/conversion_report" element={<ConversionPage />} />
          <Route path="/password_change" element={<PasswordChangePage />} />
          <Route path="/contact-us" element={<ContactUs />} />
        </Route>
        <Route element={<NormalLayout />}>
          <Route path="/login" element={<LoginForm />} />
          <Route path="/forgot_password" element={<ForgetPasswordForm />} />
          <Route path="/password_reset" element={<PasswordResetForm />} />
          <Route
            path="/email-confirmation-message"
            element={
              <Confirmation
                title={confirmTitle}
                message={confirmMsg}
                image={mailSend}
                path={"/login"}
                buttonText={"Return to login"}
                cbClick={(() => {
                    StorageUtil.removeConfirmationTexts();
                })}
              />
            }
          />
          <Route
            path="/password-confirmation-message"
            element={
              <Confirmation 
                title={confirmTitle}
                message={confirmMsg}
                image={success} 
                path={"/login"}
                buttonText={"Return to login"}
              />}
            />
        </Route>
        <Route path="*" element={<Navigate to="/errors/404" replace />} />
        {/* <Route path="*" element={<div></div>} /> */}
      </Routes>
      {/* <SettingsToggle /> */}
      <SettingsPanel />
      <ToastContainer
        closeButton={CloseButton}
        icon={false}
        position={toast.POSITION.BOTTOM_LEFT}
      />
    </>
  );
};

export default Layout;

import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { version } from 'config';

const Footer = () => (
  <footer className="footer">
    <div className="text-center fs--1 mt-4 mb-3">
      <div sm="auto">
        <p className="mb-0 text-500 me-5">
          Advertising Dashboard {' '}
          <span className="d-none d-sm-inline-block">| </span>
          {/* <br className="d-sm-none" /> {new Date().getFullYear()} &copy;{' '} */}
          <br className="d-sm-none" /> &copy;{' '}
          <a href="https://appkarma.io">Darkfield Software LLC</a>
        </p>
      </div>
      {/* <Col sm="auto">
        <p className="mb-0 text-600">v{version}</p>
      </Col> */}
    </div>
  </footer>
);

export default Footer;

import React from 'react';
import DropdownOptionKeyPair from 'util/DropdownOptionKeyPair';
import DatePicker from 'react-datepicker';
import { Button } from 'react-bootstrap';
import * as TimeUtil from 'karma_lib/util/TimeUtil.js';

export default function BasicSearchForm({
  app,
  setApp,
  appList,
  deviceType,
  setDeviceType,
  deviceTypeList,
  from,
  setFrom,
  to,
  setTo,
  cbOnClick
}) {
  return (
    <div className="row justify-content-lg-between p-3 border border-4 rounded-3 ms-4 me-4 mb-4 bg-100">
      <div className="col-6 col-lg-2 px-1 mt-2 mt-lg-0">
        <div className="fw-medium pb-1 fs--1 text-700">Application :</div>
        <DropdownOptionKeyPair
          setSelected={item => {
            setApp(item);
          }}
          selected={app}
          className={''}
          listItems={appList}
          keyName={{ key: 'entry_id', value: 'entry_disp' }}
        />
      </div>
      <div className="col-6 col-lg-2 px-1 mt-2 mt-lg-0">
        <div className="fw-medium pb-1 fs--1 text-700">Platform :</div>
        <DropdownOptionKeyPair
          setSelected={item => {
            setDeviceType(item);
          }}
          className={''}
          selected={deviceType}
          listItems={deviceTypeList}
          // keyName={{ key: 'key', value: 'value' }}
        />
      </div>
      <div className="col-6 col-lg-2 px-1 mt-2 mt-lg-0">
        <div className="fw-medium pb-1 fs--1 text-700">From :</div>
        <div
          className=""
          style={{
            marginTop: '1px'
          }}
        >
          <DatePicker
            showIcon
            className="h-100 p-1 ps-3 fw-medium border rounded-1 w-100 "
            selected={from}
            onChange={date => {
              setFrom(TimeUtil.resetToFixedTimeOfDay(date));
            }}
          />
        </div>
      </div>
      <div className="col-6 col-lg-2 px-1 mt-2 mt-lg-0">
        <div className="fw-medium pb-1 fs--1 text-700">To :</div>
        <div
          className=""
          style={{
            marginTop: '1px'
          }}
        >
          <DatePicker
            showIcon
            className="h-100 p-1 ps-3 fw-medium border rounded-1 w-100"
            selected={to}
            onChange={date => {
              setTo(TimeUtil.resetToFixedTimeOfDay(date));
            }}
          />
        </div>
      </div>
      <div className="col-12 col-lg-2 d-flex align-items-end px-1 mt-2 mt-lg-0">
        <Button className="w-100" onClick={() => cbOnClick()}>
          Submit
        </Button>
      </div>
    </div>
  );
}
